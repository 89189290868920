import React from 'react';

const LoadingSpinner = () => (
  <div
    className="fixed inset-0 flex flex-col justify-center items-center bg-gray-100 bg-opacity-75 z-50"
    role="status"
    aria-label="Loading"
  >
    {/* Rotating Gear */}
    <div className="relative w-24 h-24 border-8 border-transparent border-t-8 border-gray-800 rounded-full animate-spin">
      {/* Gear Center */}
      <div className="absolute inset-0 m-auto w-12 h-12 bg-gradient-to-r from-orange-400 via-yellow-300 to-red-500 rounded-full shadow-2xl"></div>

      {/* Satellite Balls with Extended Movement */}
      <div
        className="absolute top-0 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-blue-500 rounded-full animate-pulse-orbit"
        style={{ animationDelay: '0s' }}
      ></div>
      <div
        className="absolute left-0 top-1/2 transform -translate-y-1/2 w-4 h-4 bg-green-500 rounded-full animate-pulse-orbit"
        style={{ animationDelay: '0.3s' }}
      ></div>
      <div
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-red-500 rounded-full animate-pulse-orbit"
        style={{ animationDelay: '0.6s' }}
      ></div>
      <div
        className="absolute top-1/2 right-0 transform -translate-y-1/2 w-4 h-4 bg-yellow-500 rounded-full animate-pulse-orbit"
        style={{ animationDelay: '0.9s' }}
      ></div>
    </div>

    {/* Engaging Text */}
    <p className="mt-6 text-xl font-semibold text-gray-800 animate-bounce">
      Making things happen...
    </p>

    {/* Embedded CSS for Custom Animations */}
    <style>{`
      @keyframes pulse-orbit {
        0% {
          transform: scale(1) translateY(-50%);
        }
        50% {
          transform: scale(4) translateY(-50%); /* Increased scale for greater movement */
        }
        100% {
          transform: scale(1) translateY(-50%);
        }
      }
      .animate-pulse-orbit {
        animation: pulse-orbit 2s infinite ease-in-out;
      }
    `}</style>
  </div>
);

export default LoadingSpinner;
