import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import {
  Menu,
  X,
  ShoppingCart,
  Heart,
  UserCircle,
  ChevronDown,
  ChevronRight
} from 'lucide-react';
import { useCart } from '../contexts/CartContext';
import { useAuth } from '../contexts/AuthContext';
import { useWishlist } from '../contexts/WishlistContext';
import { getCategories } from '../services/api';
import logo from '../images/logos/logo_1.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const buildCategoryTree = (categories) => {
  const categoryMap = new Map();

  // Create nodes for all categories
  categories.forEach(category => {
    categoryMap.set(category.id, {
      ...category,
      children: []
    });
  });

  // Build the tree structure
  const rootCategories = [];
  categoryMap.forEach(category => {
    if (category.parent) {
      const parent = categoryMap.get(category.parent);
      if (parent) {
        parent.children.push(category);
      }
    } else {
      rootCategories.push(category);
    }
  });

  // Sort categories and their children recursively
  const sortCategories = (cats) => {
    return cats.sort((a, b) => a.name.localeCompare(b.name))
      .map(cat => ({
        ...cat,
        children: cat.children?.length ? sortCategories(cat.children) : []
      }));
  };

  return sortCategories(rootCategories);
};

const NavDropdown = ({ title, items, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setExpandedCategories(new Set());
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const closeDropdown = () => {
    setIsOpen(false);
    setExpandedCategories(new Set());
  };

  const toggleCategory = (categoryId, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
  };

  const handleSelect = (item) => {
    onSelect(item);
    closeDropdown();
  };

  const renderCategoryItem = (item, depth = 0) => {
    const hasChildren = item.children?.length > 0;
    const isExpanded = expandedCategories.has(item.id);

    const getStyles = () => ({
      container: `relative ${depth > 0 ? 'ml-3' : ''}`,
      button: `w-full flex items-center justify-between px-4 py-2 text-sm ${depth === 0 ? 'font-medium' : ''} 
        ${depth > 0 ? 'border-l-2 border-l-orange-200' : ''}`,
      background: depth === 0 ? 'hover:bg-gray-50'
        : depth === 1 ? 'bg-gray-50 hover:bg-gray-100'
          : 'bg-gray-100 hover:bg-gray-200'
    });

    const styles = getStyles();

    return (
      <div key={item.id} className={styles.container}>
        <div className={styles.background}>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (hasChildren) {
                toggleCategory(item.id, e);
              } else {
                handleSelect(item);
              }
            }}
            className={styles.button}
          >
            <span className="truncate">{item.name}</span>
            {hasChildren && (
              <ChevronDown
                className={`h-4 w-4 transition-transform duration-200 text-gray-400
                  ${isExpanded ? 'rotate-180' : ''}`}
              />
            )}
          </button>
        </div>

        {hasChildren && isExpanded && (
          <div className="mt-1">
            {item.children.map(child => renderCategoryItem(child, depth + 1))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="group inline-flex items-center gap-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:text-gray-900 hover:border-gray-400"
      >
        {title}
        <ChevronDown
          className={`w-4 h-4 transition-transform duration-200 text-gray-400 group-hover:text-gray-600 
            ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {isOpen && (
        <div className="absolute left-0 w-64 mt-2 origin-top-left bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50">
          <div className="h-64 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="py-2">
              {items?.map(item => renderCategoryItem(item))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const MobileCategoryMenu = ({ categories, onSelect, close }) => {
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  const renderMobileCategory = (category, depth = 0) => {
    const hasChildren = category.children?.length > 0;
    const isExpanded = expandedCategories.has(category.id);

    const getStyles = () => ({
      wrapper: `${depth > 0 ? 'ml-4' : ''}`,
      button: `flex w-full items-center justify-between px-4 py-3 text-base
        ${depth === 0 ? 'font-medium text-gray-900' : 'text-gray-700'}
        ${depth > 0 ? 'border-l-2 border-l-orange-200' : ''}
        ${depth === 0 ? '' : depth === 1 ? 'bg-gray-50' : 'bg-gray-100'}
        hover:bg-opacity-80`,
      childrenWrapper: 'mt-1 space-y-1'
    });

    const styles = getStyles();

    return (
      <div key={category.id} className={styles.wrapper}>
        <button
          onClick={() => {
            if (hasChildren) {
              setExpandedCategories(prev => {
                const newSet = new Set(prev);
                if (newSet.has(category.id)) {
                  newSet.delete(category.id);
                } else {
                  newSet.add(category.id);
                }
                return newSet;
              });
            } else {
              onSelect(category);
              close();
            }
          }}
          className={styles.button}
        >
          <span className="truncate">{category.name}</span>
          {hasChildren && (
            <ChevronDown
              className={`h-5 w-5 transition-transform duration-200
                ${isExpanded ? 'rotate-180' : ''}`}
            />
          )}
        </button>

        {hasChildren && isExpanded && (
          <div className={styles.childrenWrapper}>
            {category.children.map(child => renderMobileCategory(child, depth + 1))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="px-4 py-2 text-lg font-semibold text-gray-900">
        Categories
      </div>
      <div className="space-y-1">
        {categories.map(category => renderMobileCategory(category))}
      </div>
    </div>
  );
};

const Navbar = () => {
  const { user } = useAuth();
  const { cartItems } = useCart();
  const { wishlistItems } = useWishlist();
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [categories, setCategories] = useState([]);
  const [mobileMenuHeight, setMobileMenuHeight] = useState(0);
  const navRef = useRef(null);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const response = await getCategories();
        if (response?.data?.results) {
          const validCategories = response.data.results.filter(cat => cat && cat.id && cat.name);
          const organizedCategories = buildCategoryTree(validCategories);
          setCategories(organizedCategories);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };

    loadCategories();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    const updateMobileMenuHeight = () => {
      if (navRef.current) {
        const navHeight = navRef.current.offsetHeight;
        setMobileMenuHeight(window.innerHeight - navHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateMobileMenuHeight);
    updateMobileMenuHeight();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateMobileMenuHeight);
    };
  }, []);

  const handleProfileClick = () => {
    navigate(user ? '/profile' : '/login');
  };

  const handleCategorySelect = (category) => {
    navigate(`/search-results?category=${category.slug}`);
  };

  const cartItemCount = cartItems?.length || 0;
  const wishlistItemCount = wishlistItems?.length || 0;

  const navigationItems = [
    { name: 'HOME', href: '/' },
    { name: 'AVAILABLE ITEMS', href: '/assets' },
    { name: 'NEW ARRIVALS', href: '/new' },
    { name: 'LIST YOUR ITEM', href: '/list-asset', requiresAuth: true },
  ];

  return (
    <Disclosure as="nav" ref={navRef} className={classNames(
      'fixed w-full z-50 transition-all duration-300 bg-white shadow-md',
      isScrolled ? 'py-4' : 'py-4'
    )}>
      {({ open, close }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative flex justify-between items-center">
              {/* Logo */}
              <Link
                to="/"
                className="flex-shrink-0 flex items-center"
                onClick={() => close()}
              >
                <img
                  className={classNames(
                    'h-8 w-auto transition-all duration-300',
                    isScrolled ? 'scale-90' : ''
                  )}
                  src={logo}
                  alt="Logo"
                />
              </Link>

              {/* Desktop Navigation */}
              <div className="hidden lg:flex lg:items-center lg:justify-center flex-1 ml-8">
                <div className="flex items-center space-x-8">
                  {navigationItems.map((item) => (
                    <Link
                      key={item.name}
                      to={item.requiresAuth && !user ? '/login' : item.href}
                      className={classNames(
                        'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                        location.pathname === item.href
                          ? 'border-gray-900 text-gray-900'
                          : 'border-transparent text-gray-500 hover:text-gray-900 hover:border-gray-400'
                      )}
                      onClick={() => close()}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <NavDropdown
                    title="CATEGORIES"
                    items={categories}
                    onSelect={(category) => {
                      handleCategorySelect(category);
                      close();
                    }}
                  />
                </div>
              </div>

              {/* Desktop Icons */}
              <div className="hidden lg:flex lg:items-center lg:ml-8 space-x-6">
                <Link
                  to="/cart"
                  className="relative text-gray-500 hover:text-gray-900"
                  onClick={() => close()}
                >
                  <ShoppingCart className="h-6 w-6" />
                  {cartItemCount > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                      {cartItemCount}
                    </span>
                  )}
                </Link>

                <Link
                  to="/wishlist"
                  className="relative text-gray-500 hover:text-gray-900"
                  onClick={() => close()}
                >
                  <Heart className="h-6 w-6" />
                  {wishlistItemCount > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                      {wishlistItemCount}
                    </span>
                  )}
                </Link>

                <button
                  onClick={() => {
                    handleProfileClick();
                    close();
                  }}
                  className="relative text-gray-500 hover:text-gray-900"
                >
                  {user?.profile_picture ? (
                    <img className="h-8 w-8 rounded-full" src={user.profile_picture} alt="" />
                  ) : (
                    <UserCircle className="h-8 w-8" />
                  )}
                </button>
              </div>

              {/* Mobile menu button */}
              <div className="flex items-center lg:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-900">
                  {open ? (
                    <X className="h-6 w-6" />
                  ) : (
                    <Menu className="h-6 w-6" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile menu panel */}
          <Transition
            enter="transition duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100 scale-100"
            leave="transition duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0"
          >
            <Disclosure.Panel className="lg:hidden fixed inset-x-0 top-[72px] bottom-0 bg-white shadow-lg overflow-hidden">
              <div className="h-full flex flex-col">
                {/* Scrollable content wrapper */}
                <div className="flex-1 overflow-y-auto pb-8">
                  {/* Profile Section */}
                  <div className="p-4 border-b border-gray-200">
                    <hr></hr>
                    <Link
                      to={user ? '/profile' : '/login'}
                      className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-50"
                      onClick={() => close()}
                    >
                      <div className="flex-shrink-0">
                        {user?.profile_picture ? (
                          <img
                            className="h-12 w-12 rounded-full"
                            src={user.profile_picture}
                            alt=""
                          />
                        ) : (
                          <UserCircle className="h-12 w-12 text-gray-400" />
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="text-base font-semibold text-gray-900 truncate">
                          {user ? user.name : 'Guest'}
                        </div>
                        {user && (
                          <div className="text-sm text-gray-500 truncate">
                            {user.email}
                          </div>
                        )}
                        <div className="text-sm text-blue-600 mt-1">
                          {user ? 'View Profile' : 'Login'}
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Quick Actions */}
                  <div className="grid grid-cols-2 gap-1 p-4 border-b border-gray-200">
                    <Link
                      to="/cart"
                      className="flex flex-col items-center p-3 rounded-lg hover:bg-gray-50"
                      onClick={() => close()}
                    >
                      <div className="relative">
                        <ShoppingCart className="h-6 w-6 text-gray-600" />
                        {cartItemCount > 0 && (
                          <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                            {cartItemCount}
                          </span>
                        )}
                      </div>
                      <span className="mt-1 text-sm font-medium text-gray-700">Cart</span>
                    </Link>

                    <Link
                      to="/wishlist"
                      className="flex flex-col items-center p-3 rounded-lg hover:bg-gray-50"
                      onClick={() => close()}
                    >
                      <div className="relative">
                        <Heart className="h-6 w-6 text-gray-600" />
                        {wishlistItemCount > 0 && (
                          <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                            {wishlistItemCount}
                          </span>
                        )}
                      </div>
                      <span className="mt-1 text-sm font-medium text-gray-700">Wishlist</span>
                    </Link>
                  </div>

                  {/* Navigation Links */}
                  <div className="py-2 border-b border-gray-200">
                    {navigationItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.requiresAuth && !user ? '/login' : item.href}
                        className={classNames(
                          'block w-full px-4 py-3 text-base font-medium text-left hover:bg-gray-50',
                          location.pathname === item.href
                            ? 'text-blue-600 bg-blue-50'
                            : 'text-gray-700'
                        )}
                        onClick={() => {
                          close();
                        }}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  {/* Categories Section */}
                  <MobileCategoryMenu
                    categories={categories}
                    onSelect={handleCategorySelect}
                    close={close}
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;