import axios from 'axios';
import { setupCache, buildMemoryStorage } from 'axios-cache-interceptor';
import rateLimit from 'axios-rate-limit';
import pako from 'pako';

// API versioning support
const API_VERSION = 'v1';
const createVersionedUrl = (url) => `/api/${API_VERSION}/${url.replace(/^\//, '')}`;

// Configuration
const CONFIG = {
  API: {
    // BASE_URL: 'http://127.0.0.1:8000',
    BASE_URL: 'https://api.alihire.com',
    TIMEOUT: 30000, // Reduced global timeout
    MAX_RETRIES: 3,
    RETRY_DELAYS: [1000, 2000, 4000],
    REQUESTS_PER_SECOND: 20, // Increased for better performance
    ENDPOINT_TIMEOUTS: {
      'api/assets/upload': 600000,
      'api/assets/download': 600000,
      'api/assets/assets/popular': 600000, // 
      'api/users/vendors/dashboard/': 60000, // 60 seconds for dashboard
      'api/users/vendors/earnings/': 30000,
      'api/users/admin/orders/dashboard-stats/': 30000,
      'default': 30000
    },
    RATE_LIMITS: {
      'api/assets/popular': {
        maxRequests: 30,
        perMilliseconds: 30000 // Reduced to 30 seconds
      },
      'api/assets': {
        maxRequests: 50,
        perMilliseconds: 60000
      },
      'default': {
        maxRequests: 30,
        perMilliseconds: 60000
      }
    }
  },
  CACHE: {
    MAX_ITEMS: 1000, // Increased cache size
    TTL: 5 * 60 * 1000,
    CLEANUP_INTERVAL: 60000
  },
  WEBSOCKET: {
    RECONNECT_ATTEMPTS: 5,
    RECONNECT_INTERVAL: 1000,
    PING_INTERVAL: 30000
  }
};

// API instance with enhanced configuration
const baseApi = axios.create({
  baseURL: CONFIG.API.BASE_URL,
  timeout: CONFIG.API.TIMEOUT,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

const POPULAR_ASSETS_CACHE = new Map();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

//request timeout handling
const TIMEOUT_DURATION = 300000;
const createTimeoutPromise = () => {
  return new Promise((_, reject) => {
    setTimeout(() => reject(new Error('Request timeout')), TIMEOUT_DURATION);
  });
};


// URL normalization utility
const normalizeUrl = (url) => {
  if (!url) return '/';

  // Don't modify absolute URLs
  if (url.startsWith('http')) {
    return url;
  }

  // Clean up multiple forward slashes and normalize
  let normalized = url.replace(/\/+/g, '/');

  // Remove leading 'api/' if present
  normalized = normalized.replace(/^api\//, '');

  // Ensure URL starts with forward slash
  if (!normalized.startsWith('/')) {
    normalized = '/' + normalized;
  }

  // Add trailing slash if missing and not a query parameter URL
  if (!normalized.endsWith('/') && !normalized.includes('?')) {
    normalized = normalized + '/';
  }

  return normalized;
};


// Add circuit breaker implementation
class CircuitBreaker {
  constructor() {
    this.failures = 0;
    this.isOpen = false;
    this.lastFailure = null;
  }

  async execute(request) {
    if (this.isOpen) {
      // Check if circuit should be reset
      if (Date.now() - this.lastFailure > 60000) {
        this.reset();
      } else {
        throw new Error('Circuit breaker is open');
      }
    }

    try {
      const result = await request();
      this.success();
      return result;
    } catch (error) {
      this.failure();
      throw error;
    }
  }

  success() {
    this.failures = 0;
    this.isOpen = false;
  }

  failure() {
    this.failures++;
    this.lastFailure = Date.now();
    if (this.failures >= 5) {
      this.isOpen = true;
    }
  }

  reset() {
    this.failures = 0;
    this.isOpen = false;
    this.lastFailure = null;
  }
}

//request prioritization
const PRIORITY = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3
};

class PriorityQueue {
  constructor() {
    this.queue = [];
  }

  add(request, priority = PRIORITY.MEDIUM) {
    this.queue.push({ request, priority });
    this.queue.sort((a, b) => a.priority - b.priority);
  }

  process() {
    while (this.queue.length) {
      const { request } = this.queue.shift();
      request();
    }
  }
}

// Enhanced Cache Manager (while maintaining backward compatibility)
class CacheManager {
  constructor() {
    this.cache = new Map();
    this.maxItems = CONFIG.CACHE.MAX_ITEMS;
    this.ttl = CONFIG.CACHE.TTL;

    this.metrics = {
      hits: 0,
      misses: 0,
      total: 0
    };

    // Start cleanup interval
    setInterval(() => this.cleanup(), CONFIG.CACHE.CLEANUP_INTERVAL);
  }

  set(key, value) {
    if (this.cache.size >= this.maxItems) {
      // Remove oldest item
      const firstKey = this.cache.keys().next().value;
      this.cache.delete(firstKey);
    }

    this.cache.set(key, {
      data: value,
      timestamp: Date.now()
    });
  }

  get(key) {
    const entry = this.cache.get(key);
    if (!entry) {
      this.metrics.misses++;
      return null;
    }

    if (Date.now() - entry.timestamp > this.ttl) {
      this.cache.delete(key);
      this.metrics.misses++;
      return null;
    }

    this.metrics.hits++;
    return entry.data;
  }

  has(key) {
    if (!this.cache.has(key)) return false;

    const entry = this.cache.get(key);
    if (Date.now() - entry.timestamp > this.ttl) {
      this.cache.delete(key);
      return false;
    }

    return true;
  }

  delete(key) {
    return this.cache.delete(key);
  }

  clear() {
    this.cache.clear();
    this.metrics = { hits: 0, misses: 0, total: 0 };
  }

  cleanup() {
    const now = Date.now();
    for (const [key, entry] of this.cache.entries()) {
      if (now - entry.timestamp > this.ttl) {
        this.cache.delete(key);
      }
    }
  }
}

// Browser-compatible Request Queue Manager
class RequestQueueManager {
  constructor(maxConcurrent = 3) {
    this.queue = [];
    this.running = 0;
    this.maxConcurrent = maxConcurrent;
    this.pending = new Map();
  }

  async enqueue(key, request) {
    if (this.pending.has(key)) {
      return this.pending.get(key);
    }

    const promise = new Promise((resolve, reject) => {
      const task = async () => {
        try {
          this.running++;
          const result = await request();
          resolve(result);
          return result;
        } catch (error) {
          reject(error);
        } finally {
          this.running--;
          this.pending.delete(key);
          this.processQueue();
        }
      };

      this.queue.push(task);

      // Start processing if we're not at max capacity
      if (this.running < this.maxConcurrent) {
        this.processQueue();
      }
    });

    this.pending.set(key, promise);
    return promise;
  }

  processQueue() {
    while (this.queue.length > 0 && this.running < this.maxConcurrent) {
      const task = this.queue.shift();
      task().catch(error => {

      });
    }
  }

  clear() {
    this.queue = [];
    this.running = 0;
    this.pending.clear();
  }
}

// Enhanced Error Handler
class ApiError extends Error {
  constructor(message, code, response, extra = {}) {
    super(message);
    this.name = 'ApiError';
    this.code = code;
    this.response = response;
    this.isApiError = true;
    this.timestamp = new Date();
    Object.assign(this, extra);
  }

  static fromResponse(error) {
    const { response } = error;

    if (response) {
      return new ApiError(
        response.data?.message || response.data?.error || 'API request failed',
        response.status,
        response,
        { originalError: error }
      );
    }

    if (error.request) {
      return new ApiError(
        'Network error. Please check your connection.',
        'NETWORK_ERROR',
        null,
        { originalError: error }
      );
    }

    return new ApiError(
      error.message,
      'UNKNOWN_ERROR',
      null,
      { originalError: error }
    );
  }
}

//backward compatible instances
const cache = new CacheManager();
const requestQueue = new RequestQueueManager();
const storage = buildMemoryStorage();


const rateLimitedApi = rateLimit(baseApi, (req) => {
  const endpoint = Object.keys(CONFIG.API.RATE_LIMITS)
    .find(key => req.url.includes(key)) || 'default';
  return CONFIG.API.RATE_LIMITS[endpoint];
});

const api = setupCache(rateLimitedApi, {
  storage: buildMemoryStorage(),
  ttl: CONFIG.CACHE.TTL,
  interpretHeader: false,
  methods: ['get'],
  debug: process.env.NODE_ENV === 'development',
  // Add cache key generator
  generateKey: req => {
    const url = req.url.toLowerCase();
    const params = JSON.stringify(req.params || {});
    return `${url}_${params}`;
  }
});

const timeoutInterceptor = (config) => {
  // Get endpoint-specific timeout or default
  const endpoint = Object.keys(CONFIG.API.ENDPOINT_TIMEOUTS)
    .find(key => config.url.includes(key)) || 'default';

  config.timeout = CONFIG.API.ENDPOINT_TIMEOUTS[endpoint];
  return config;
};

if (!api.defaults.headers) {
  api.defaults.headers = {};
}

if (!api.defaults.headers.common) {
  api.defaults.headers.common = {};
}

//  WebSocket Implementation for Real-time Features
class WebSocketManager {
  constructor() {
    this.socket = null;
    this.reconnectAttempts = 0;
    this.listeners = new Map();
    this.queue = [];
  }

  connect() {
    const wsUrl = CONFIG.API.BASE_URL.replace(/^http/, 'ws') + '/ws/';
    this.socket = new WebSocket(wsUrl);

    this.socket.onopen = () => {
      this.reconnectAttempts = 0;
      this.processQueue();
      this.startPingInterval();
    };

    this.socket.onclose = () => {
      this.handleReconnect();
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.notifyListeners(data);
    };

    this.socket.onerror = (error) => {

    };
  }

  handleReconnect() {
    if (this.reconnectAttempts < CONFIG.WEBSOCKET.RECONNECT_ATTEMPTS) {
      setTimeout(() => {
        this.reconnectAttempts++;
        this.connect();
      }, CONFIG.WEBSOCKET.RECONNECT_INTERVAL * this.reconnectAttempts);
    }
  }

  startPingInterval() {
    this.pingInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'ping' }));
      }
    }, CONFIG.WEBSOCKET.PING_INTERVAL);
  }

  subscribe(event, callback) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }
    this.listeners.get(event).add(callback);
  }

  unsubscribe(event, callback) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).delete(callback);
    }
  }

  notifyListeners(data) {
    if (this.listeners.has(data.type)) {
      this.listeners.get(data.type).forEach(callback => callback(data.payload));
    }
  }

  send(data) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      this.queue.push(data);
    }
  }

  processQueue() {
    while (this.queue.length > 0) {
      const data = this.queue.shift();
      this.send(data);
    }
  }

  disconnect() {
    if (this.socket) {
      clearInterval(this.pingInterval);
      this.socket.close();
    }
  }
}

// Request Queue for Offline Support
class OfflineRequestQueue {
  constructor() {
    this.queue = [];
    this.isOnline = navigator.onLine;

    window.addEventListener('online', this.handleOnline.bind(this));
    window.addEventListener('offline', () => this.isOnline = false);
  }

  add(request) {
    if (!this.isOnline) {
      this.queue.push(request);
      return new Promise((resolve, reject) => {
        request.resolve = resolve;
        request.reject = reject;
      });
    }
    return null;
  }

  async handleOnline() {
    this.isOnline = true;
    while (this.queue.length > 0) {
      const request = this.queue.shift();
      try {
        const response = await api(request.config);
        request.resolve(response);
      } catch (error) {
        request.reject(error);
      }
    }
  }
}

// Request/Response Compression
const compressionInterceptor = (config) => {
  if (config.data && typeof config.data === 'string' && config.data.length > 1024) {
    const compressed = pako.deflate(config.data);
    config.data = compressed;
    config.headers['Content-Encoding'] = 'gzip';
  }
  return config;
};

//  API Version Handler
const versionInterceptor = (config) => {
  // Don't modify if it's an absolute URL
  if (config.url.startsWith('http')) {
    return config;
  }

  // Clean URL and normalize
  let cleanUrl = config.url.replace(/^\/?(api\/)+/, '');
  cleanUrl = normalizeUrl(cleanUrl);

  // Add API prefix
  config.url = `api${cleanUrl}`;
  return config;
};


// Apply all interceptors
api.interceptors.request.use(timeoutInterceptor);
api.interceptors.request.use(compressionInterceptor);
api.interceptors.request.use(versionInterceptor);
api.interceptors.request.use(config => {
  if (config.url.includes('popular')) {
    config.url = config.url.replace('/api/api/', '/api/');
  }
  return config;
});

// Initialize managers
export const wsManager = new WebSocketManager();
export const offlineQueue = new OfflineRequestQueue();

// Enhanced CSRF token manager
const csrfTokenManager = {
  token: null,
  retries: 0,
  maxRetries: 3, // Reduced from 7
  retryDelay: 1000,
  lastFetch: 0,
  minFetchInterval: 5000, // Prevent rapid refetching

  async getToken() {
    const now = Date.now();

    // Return existing token if it's not too old
    if (this.token && now - this.lastFetch < 300000) { // 5 minutes
      return this.token;
    }

    // Prevent rapid refetching
    if (now - this.lastFetch < this.minFetchInterval) {
      return this.token || null;
    }

    if (this.retries >= this.maxRetries) {
      this.reset();
      throw new Error('Maximum CSRF token fetch retries exceeded');
    }

    try {

      const response = await axios.get(`${CONFIG.API.BASE_URL}/api/users/get-csrf-token/`, {
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      if (response.data?.csrfToken) {
        this.token = response.data.csrfToken;
        this.lastFetch = now;
        axios.defaults.headers.common['X-CSRFToken'] = this.token;
        this.reset();
        return this.token;
      }

      throw new Error('Invalid CSRF token response');
    } catch (error) {
      this.retries++;
      if (this.retries < this.maxRetries) {
        await new Promise(resolve => setTimeout(resolve, this.retryDelay * this.retries));
        return this.getToken();
      }
      throw error;
    }
  },

  reset() {
    this.retries = 0;
  },

  clearToken() {
    this.token = null;
    this.lastFetch = 0;
    delete axios.defaults.headers.common['X-CSRFToken'];
  }
};

// Export the initializeCSRFToken function
export const initializeCSRFToken = async (retries = 3) => {
  const attemptInitialize = async (retriesLeft) => {
    try {
      const token = await csrfTokenManager.getToken();
      return token;
    } catch (error) {
      if (retriesLeft > 0) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return attemptInitialize(retriesLeft - 1);
      }
      throw error;
    }
  };

  return attemptInitialize(retries);
};

export const fetchCSRFToken = async () => {
  try {
    const response = await api.get('users/get-csrf-token/', {
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache'
      }
    });

    if (response.data?.csrfToken) {
      api.defaults.headers.common['X-CSRFToken'] = response.data.csrfToken;
      return response.data.csrfToken;
    }

    throw new Error('Invalid CSRF token response');
  } catch (error) {

    throw error;
  }
};




// Request interceptor
api.interceptors.request.use(
  async config => {
    const DEBUG = process.env.NODE_ENV === 'development';

    // Ensure base URL is set
    if (!config.baseURL) {
      config.baseURL = CONFIG.API.BASE_URL;
    }

    // Normalize URL before processing
    config.url = normalizeUrl(config.url);

    // Don't try to get CSRF token for csrf token endpoint or GET requests
    const needsCsrfToken = !config.url.includes('get-csrf-token') &&
      config.method?.toLowerCase() !== 'get';

    if (needsCsrfToken) {
      try {
        const token = await csrfTokenManager.getToken();
        if (token) {
          config.headers['X-CSRFToken'] = token;
        }
      } catch (error) {

      }
    }

    // Authentication token
    const authToken = localStorage.getItem('accessToken');
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }

    // Handle FormData
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
      config.headers['Accept'] = 'application/json, multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    // Common headers
    config.headers = {
      ...config.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/json'
    };

    // Ensure credentials are always sent
    config.withCredentials = true;


    return config;
  },
  error => Promise.reject(error)
);


// Response interceptor
api.interceptors.response.use(
  response => response,
  async error => {
    // Handle CSRF token errors
    if (error.response?.status === 403 && error.response?.data?.detail?.includes('CSRF')) {
      csrfTokenManager.clearToken();
      // Retry the request once
      const config = error.config;
      if (!config._retryCount) {
        config._retryCount = 1;
        return api(config);
      }
    }

    // Handle authentication errors
    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      cache.delete('userProfile');
      delete api.defaults.headers.common['Authorization'];
    }

    return Promise.reject(error);
  }
);

const createApiFunction = (method, url, options = {}) => {
  return async (params = {}, data = {}, cancelToken = null) => {
    // Clean params
    const cleanedParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== 'undefined')
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value
      }), {});

    // Normalize URL and replace parameters
    const processedUrl = replaceUrlParams(url, cleanedParams);
    const cleanUrl = processedUrl.replace(/^api\//, '');

    const requestKey = `${method}-${cleanUrl}-${JSON.stringify(cleanedParams)}-${JSON.stringify(data)}`;

    // Handle offline mode
    if (!navigator.onLine) {
      return offlineQueue.add({
        config: { method, url: cleanUrl, params: cleanedParams, data, ...options }
      });
    }

    // Cache handling for GET requests
    if (method.toLowerCase() === 'get' && cache.has(requestKey)) {
      const cached = cache.get(requestKey);
      if (cached) return cached;
    }

    const request = async () => {
      try {
        const config = {
          method,
          url: cleanUrl,
          params: cleanedParams,
          data: method.toLowerCase() !== 'get' ? data : undefined,
          cancelToken: cancelToken?.token,
          ...options
        };

        const response = await api(config);

        // Cache successful GET responses
        if (method.toLowerCase() === 'get') {
          cache.set(requestKey, response.data);
        }

        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          throw error;
        }
        throw ApiError.fromResponse(error);
      }
    };

    return requestQueue.enqueue(requestKey, request);
  };
};


// Maintain legacy functions for backward compatibility
export const createCancelToken = () => axios.CancelToken.source();

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Helper function to generate unique request IDs (maintained for compatibility)
const generateRequestId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) >>> 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};


// Error handling utility function
const handleApiError = (error) => {


  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errorMessage = error.response.data?.message ||
      error.response.data?.error ||
      'An error occurred while processing your request';
    throw new ApiError(errorMessage, error.response.status, error.response);
  } else if (error.request) {
    // The request was made but no response was received
    throw new ApiError('No response received from server', 'NETWORK_ERROR', null);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new ApiError(error.message, 'REQUEST_ERROR', null);
  }
};

// Retry utility with exponential backoff
const retryWithBackoff = async (fn, maxRetries = 3) => {
  let retries = 0;
  while (retries < maxRetries) {
    try {
      return await fn();
    } catch (error) {
      retries++;
      if (retries === maxRetries) {
        throw error;
      }
      // Exponential backoff: 1s, 2s, 4s
      const delay = Math.pow(2, retries - 1) * 1000;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
};

// Auth status check utility
export const checkAuthStatus = async () => {
  try {

    const token = localStorage.getItem('accessToken');
    if (!token) {
      return { isAuthenticated: false };
    }

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000);

    const response = await api.get('api/users/auth-status/', {
      signal: controller.signal,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    clearTimeout(timeoutId);
    return {
      isAuthenticated: true,
      user: response.data
    };
  } catch (error) {

    if (error.name === 'AbortError') {

    }
    return { isAuthenticated: false };
  }
};

// Batch requests utility
const batchRequests = async (requests, batchSize = 5) => {
  const results = [];
  for (let i = 0; i < requests.length; i += batchSize) {
    const batch = requests.slice(i, i + batchSize);
    const batchResults = await Promise.all(batch.map(request => request()));
    results.push(...batchResults);
  }
  return results;
};

// Cleanup utility
const cleanup = () => {
  cache.clear();
  requestQueue.clear();
  api.interceptors.request.handlers = [];
  api.interceptors.response.handlers = [];
};


// Helper function to replace URL parameters
const replaceUrlParams = (url, params) => {
  let processedUrl = url;
  if (params && typeof params === 'object') {
    Object.entries(params).forEach(([key, value]) => {
      const placeholder = `{${key}}`;
      const encodedValue = encodeURIComponent(value);
      processedUrl = processedUrl.replace(placeholder, encodedValue);
    });
  }
  return normalizeUrl(processedUrl);
};

// User Authentication
export const login = async (identifier, password) => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000); // 15s timeout


    const response = await api.post('users/login/', {
      identifier,
      password
    }, {
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true
    });

    clearTimeout(timeoutId);

    if (!response.data || !response.data.access || !response.data.refresh) {
      throw new Error('Invalid login response format');
    }


    return response.data;
  } catch (error) {


    if (error.name === 'AbortError') {
      throw new Error('Login request timed out');
    }

    if (!error.response && error.request) {
      throw new Error('Network error. Please check your connection and try again.');
    }

    const errorMessage = error.response?.data?.message ||
      error.response?.data?.detail ||
      error.message ||
      'Authentication failed';

    throw new Error(errorMessage);
  }
};


// Refresh token function
export const refreshAccessToken = async () => {
  try {

    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10s timeout

    const response = await api.post('/api/users/token/refresh/', {
      refresh: refreshToken
    }, {
      signal: controller.signal,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    clearTimeout(timeoutId);

    if (response.data?.access) {
      localStorage.setItem('accessToken', response.data.access);
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
      return response.data.access;
    }

    throw new Error('Invalid refresh token response');
  } catch (error) {

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete api.defaults.headers.common['Authorization'];

    if (error.name === 'AbortError') {
      throw new Error('Token refresh request timed out');
    }

    if (!error.response && error.request) {
      throw new Error('Network error during token refresh');
    }

    throw new Error(
      error.response?.data?.message ||
      error.response?.data?.detail ||
      error.message ||
      'Failed to refresh token'
    );
  }
};

export const register = async (userData) => {
  try {
    const response = await api.post('/api/users/register/', userData);
    if (userData.referral_code) {
      await trackReferral(userData.referral_code);
    }
    return response.data;
  } catch (error) {

    throw error;
  }
};
export const registerVendor = createApiFunction('post', 'api/users/register-vendor/');
export const logout = async () => {
  try {
    await api.post('/api/users/logout/');
  } finally {
    // Clear everything regardless of logout API success
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete api.defaults.headers.common['Authorization'];
    csrfTokenManager.clearToken();
  }
};

export const getUserProfile = async (options = {}) => {
  try {

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {

      throw new Error('Authentication required');
    }

    // Check cache unless force refresh is requested
    if (!options.force) {
      const cachedProfile = cache.get('userProfile');
      if (cachedProfile && Date.now() - cachedProfile.timestamp < 300000) {

        return cachedProfile.data;
      }
    }

    // Create abort controller for timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), options.timeout || 15000);

    const config = {
      signal: controller.signal,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true,
      ...options
    };

    try {
      const response = await api.get('api/users/me/', config);
      clearTimeout(timeoutId);

      if (!response.data || typeof response.data !== 'object') {
        throw new Error('Invalid profile data received');
      }

      const normalizedData = {
        id: response.data.id || '',
        first_name: response.data.first_name || '',
        last_name: response.data.last_name || '',
        email: response.data.email || '',
        phone_number: response.data.phone_number || '',
        date_of_birth: response.data.date_of_birth || '',
        id_number: response.data.id_number || '',
        address: response.data.address || '',
        bio: response.data.bio || '',
        profile_picture: response.data.profile_picture || null,
        date_joined: response.data.date_joined || new Date().toISOString(),
        is_vendor: Boolean(response.data.is_vendor),
        is_staff: Boolean(response.data.is_staff),
        vendorship_requested: Boolean(response.data.vendorship_requested),
        roles: response.data.roles || [],
        permissions: response.data.permissions || [],
        timestamp: Date.now()
      };

      cache.set('userProfile', {
        data: normalizedData,
        timestamp: Date.now()
      });

      return normalizedData;
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  } catch (error) {


    if (error.name === 'AbortError') {
      throw new Error('Profile request timed out');
    }

    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      cache.delete('userProfile');
      throw new Error('Session expired. Please login again.');
    }

    if (!error.response && error.request) {
      throw new Error('Network error. Please check your connection.');
    }

    throw new Error(
      error.response?.data?.message ||
      error.response?.data?.detail ||
      error.message ||
      'Failed to fetch user profile'
    );
  }
};


export const resetPassword = createApiFunction('post', 'api/users/password-reset/');
export const confirmResetPassword = createApiFunction('post', 'api/users/password-reset-confirm/');
export const activateAccount = createApiFunction('get', 'api/users/activate/{uidb64}/{token}/');
export const resendActivation = createApiFunction('post', 'api/users/resend-activation/');

export const loginWithGoogle = async (idToken) => {
  try {
    const response = await api.post('/api/users/google/', { id_token: idToken });
    return response.data;
  } catch (error) {

    if (error.response) {

    } else if (error.request) {

    } else {

    }
    throw error;
  }
};

export const loginWithFacebook = createApiFunction('post', 'api/users/facebook/');
export const refreshToken = createApiFunction('post', 'api/users/token/refresh/');
export const updateUserProfile = async (formData) => {
  try {
    const config = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        // Remove Content-Type to let browser set it with boundary
      },
    };

    // Log the FormData entries in development
    if (process.env.NODE_ENV === 'development') {
      for (let pair of formData.entries()) {
     
      }
    }

    const response = await api.patch('api/users/me/', formData, config);
    
    return response.data;
  } catch (error) {

    
    // Enhanced error handling
    const errorMessage = error.response?.data?.message || 
                        error.response?.data?.detail ||
                        'Failed to update profile';
                        
    throw new Error(errorMessage);
  }
};
// Admin-specific functions
export const getAdminDashboard = async () => {
  try {
    const response = await api.get('api/users/admin/dashboard/');
    return response;
  } catch (error) {
  
    throw error;
  }
};

export const deleteAdminUser = createApiFunction('delete', 'api/users/admin/users/{id}/');

// User data validator function
const validateUserData = (userData) => {
  // Handle nested user object or direct data
  const user = userData?.user || userData || {};
  
  return {
    id: user?.id || '',
    username: user?.username || 'Unknown User',
    email: user?.email || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    is_active: Boolean(user?.is_active),
    is_staff: Boolean(user?.is_staff),
    is_vendor: Boolean(user?.is_vendor),
    date_joined: user?.date_joined || new Date().toISOString(),
    last_login: user?.last_login || null,
    profile_picture: user?.profile_picture || null,
    phone_number: user?.phone_number || '',
    address: user?.address || '',
    vendorship_requested: Boolean(user?.vendorship_requested)
  };
};

// Enhanced getAdminUsers function
export const getAdminUsers = async (params = {}) => {
  try {
    const response = await api.get('/api/users/admin/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
        limit: params.limit || 10,
        ordering: params.ordering || '-date_joined'
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Debug logging in development
    if (process.env.NODE_ENV === 'development') {
 
    }

    if (!response?.data) {
      throw new Error('Invalid response from server');
    }

    // Handle different response structures
    let results = [];
    let totalCount = 0;

    if (Array.isArray(response.data)) {
      // Handle array response
      results = response.data;
      totalCount = response.data.length;
    } else if (response.data.results && Array.isArray(response.data.results)) {
      // Handle paginated response
      results = response.data.results;
      totalCount = response.data.count || results.length;
    } else if (typeof response.data === 'object') {
      // Handle single user response
      results = [response.data];
      totalCount = 1;
    } else {
      throw new Error('Unexpected response format');
    }

    // Normalize each user object using the validator
    const normalizedUsers = results.map(userData => {
      const validatedUser = validateUserData(userData);
      
      // Debug logging in development
      if (process.env.NODE_ENV === 'development') {
      
      }
      
      return validatedUser;
    });

    // Return normalized response
    return {
      data: {
        results: normalizedUsers,
        count: totalCount,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };

  } catch (error) {
    
    
    // Enhanced error handling
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new Error(
        error.response.data?.message || 
        error.response.data?.detail || 
        'Failed to fetch users'
      );
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('Network error. Please check your connection.');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(error.message || 'An error occurred while fetching users');
    }
  }
};

// Helper function to fetch user details if needed
export const getUserDetails = async (userId) => {
  try {
    const response = await api.get(`/api/users/admin/${userId}/`);
    return validateUserData(response.data);
  } catch (error) {
 
    throw new Error(error.response?.data?.message || 'Failed to fetch user details');
  }
};

// Function to update user data
export const updateAdminUser = async (userId, userData) => {
  try {
    const response = await api.put(`/api/users/admin/users/${userId}/`, userData);
    return validateUserData(response.data);
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to update user');
  }
};

// Function to create new user
export const createAdminUser = async (userData) => {
  try {
    const response = await api.post('/api/users/admin/users/', userData);
    return validateUserData(response.data);
  } catch (error) {
 
    throw new Error(error.response?.data?.message || 'Failed to create user');
  }
};

// Optional: Add a function to handle bulk user operations if needed
export const bulkUpdateUsers = async (userIds, updateData) => {
  try {
    const response = await api.post('/api/users/admin/bulk-update/', {
      user_ids: userIds,
      update_data: updateData
    });
    return response.data;
  } catch (error) {
 
    throw new Error(error.response?.data?.message || 'Failed to perform bulk update');
  }
};


export const getDashboardStats = createApiFunction('get', 'api/users/admin/orders/dashboard-stats/');
export const getRecentOrders = async (params = {}) => {
  try {
    const response = await api.get('api/users/admin/orders/recent-orders/', { 
      params,
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    if (!response || !response.data) {
      throw new Error('Invalid response from server');
    }

    return response;
  } catch (error) {
   
    throw error;
  }
};
export const updateOrderStatus = createApiFunction('patch', 'api/users/admin/orders/{id}/update-status/');
export const getOrderDetails = createApiFunction('get', 'api/users/admin/orders/{id}/details/');

// User Profile
export const getUserPreferences = createApiFunction('get', 'api/users/preferences/');
export const updateUserPreferences = createApiFunction('patch', 'api/users/preferences/');
export const getUserOrders = async (params = {}) => {
  try {
    const response = await api.get('api/orders/', {  // Notice: using main endpoint
      params: {
        page: params.page || 1,
        search: params.search || undefined,
        status: params.status === 'all' ? undefined : params.status,
        ordering: '-order_date'
      }
    });
    return response;
  } catch (error) {

    throw error;
  }
};

export const changePassword = (data) => api.post('api/users/change-password/', data).catch(handleApiError);
export const deleteAccount = createApiFunction('delete', 'api/users/delete-account/');
export const updateProfilePhoto = (formData) => {
  return api.patch('api/users/update-profile-photo/', formData, {
    headers: {
      'Accept': 'application/json',
    }
  }).catch(handleApiError);
};
export const becomeVendor = (vendorData) => {

  return api.post('api/users/become-vendor/', vendorData)
    .then(response => {

      return response.data;
    })
    .catch(error => {

      throw error;
    });
};

// Assets
export const getCategories = async (params = {}) => {
  try {
    const response = await api.get('api/assets/categories/', {
      params,
      headers: {
        'Cache-Control': 'max-age=300',
        'Accept': 'application/json',
      }
    });

    // Validate response data
    if (!response.data || (!Array.isArray(response.data.results) && !Array.isArray(response.data))) {
      throw new Error('Invalid response format from categories endpoint');
    }

    // Handle both paginated and non-paginated responses
    const results = response.data.results || response.data;

    return {
      data: {
        results: results.map(category => ({
          ...category,
          id: category.id || null,
          name: category.name || '',
          slug: category.slug || '',
          description: category.description || ''
        })),
        count: response.data.count || results.length
      }
    };
  } catch (error) {

    if (error.response) {

      throw new Error(error.response.data.message || 'Failed to fetch categories');
    }
    throw error;
  }
};

export const getLocations = async (params = {}) => {
  try {
    // Validate search term length
    const searchTerm = params.search?.trim() || '';
    if (searchTerm.length < 2) {
      return {
        data: {
          results: [],
          count: 0,
          message: "Please enter at least 2 characters to search"
        }
      };
    }

    // Create valid query parameters
    const queryParams = new URLSearchParams({
      search: searchTerm,
      page: params.page || '1',
      limit: params.limit || '10'
    });

    // Add optional parameters
    if (params.country) {
      queryParams.append('country', params.country);
    }

    const response = await api.get(`api/assets/locations/?${queryParams.toString()}`, {
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'max-age=300'
      }
    });

    if (!response?.data) {
      throw new Error('Invalid response format from locations endpoint');
    }

    return {
      data: {
        results: response.data.results || [],
        count: response.data.count || 0,
        message: response.data.message || null
      }
    };
  } catch (error) {
  
    throw new Error(error.response?.data?.message || 'Failed to fetch locations');
  }
};


export const getAssetDetails = async (slug) => {
  try {
    if (!slug) {
      throw new Error('Slug is required');
    }
    const response = await api.get(`api/assets/assets/${slug}/`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      throw new Error(`Asset not found for slug: ${slug}`);
    }
    throw error;
  }
};

export const getSimilarAssets = async (slug) => {
  try {
    if (!slug) {
      throw new Error('Slug is required');
    }
    const response = await api.get(`api/assets/assets/${slug}/similar/`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      throw new Error(`Similar assets not found for slug: ${slug}`);
    }
    throw error;
  }
};

export const getAssetReviews = async (slug) => {
  try {
    if (!slug) {
      throw new Error('Slug is required');
    }
    const response = await api.get(`api/reviews/assets/${slug}/reviews/`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      throw new Error(`Reviews not found for slug: ${slug}`);
    }
    throw error;
  }
};


export const addMaintenanceRecord = createApiFunction('post', 'api/assets/assets/{slug}/add-maintenance/');
export const updateAssetValue = createApiFunction('post', 'api/assets/assets/{slug}/update-value/');
export const toggleAssetFeatured = createApiFunction('post', 'api/assets/assets/{slug}/toggle-featured/');
export const getAssetStats = createApiFunction('get', 'api/assets/assets/stats/');
export const getPopularAssets = createApiFunction('get', 'api/assets/assets/popular/');
export const exploreAssets = createApiFunction('get', 'api/assets/assets/explore/');
export const updateInventoryQuantity = createApiFunction('post', 'api/assets/inventories/{pk}/update-quantity/');
export const getVendorAssets = createApiFunction('get', 'api/assets/vendor-assets/');
export const getVendorEarnings = createApiFunction('get', 'api/users/vendors/earnings/');
export const getAssetLocation = async (slug, orderId = null) => {
  try {
    if (!slug) {
      throw new Error('Asset slug is required');
    }

    // Ensure proper URL formatting by removing any leading/trailing slashes
    const sanitizedSlug = slug.replace(/^\/+|\/+$/g, '');

    // Build the URL with proper structure
    const url = `api/assets/assets/${sanitizedSlug}/location`;

    // Add order_id as query parameter if provided
    const params = orderId ? { order_id: orderId } : {};

    const response = await api.get(url, { params });

    if (!response.data) {
      throw new Error('No location data received');
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      // Handle specific HTTP error codes
      switch (error.response.status) {
        case 404:
          throw new Error(`Asset with slug "${slug}" not found`);
        case 403:
          throw new Error('You do not have permission to access this asset\'s location');
        default:
          throw new Error(`Error fetching asset location: ${error.response.data.message || error.response.data}`);
      }
    }
    // Handle network or other errors

    throw error;
  }
};

export const searchAssetsOnMap = async (mapBounds) => {
  let retries = 3;
  const baseDelay = 1000;

  while (retries > 0) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000);

    try {
      // Ensure coordinates are proper numbers
      const params = {
        ne_lat: parseFloat(mapBounds.ne.lat).toFixed(6),
        ne_lng: parseFloat(mapBounds.ne.lng).toFixed(6),
        sw_lat: parseFloat(mapBounds.sw.lat).toFixed(6),
        sw_lng: parseFloat(mapBounds.sw.lng).toFixed(6)
      };

      // Validate bounds are within Kenya
      if (params.ne_lat < -4.8 || params.ne_lat > 5.5 ||
          params.sw_lat < -4.8 || params.sw_lat > 5.5 ||
          params.ne_lng < 33.9 || params.ne_lng > 41.9 ||
          params.sw_lng < 33.9 || params.sw_lng > 41.9) {
        throw new Error('Coordinates must be within Kenya\'s bounds');
      }

      const response = await api.get('api/assets/assets/map-search/', {
        params,
        signal: controller.signal,
        headers: {
          'Cache-Control': 'max-age=300',
          'Accept': 'application/json'
        }
      });

      clearTimeout(timeoutId);

      // Transform response data to ensure consistent format
      const assets = response.data.map(asset => ({
        ...asset,
        location: asset.location || {
          latitude: 0,
          longitude: 0,
          is_exact: false
        },
        randomized_location: asset.location || {
          latitude: 0,
          longitude: 0,
          is_exact: false
        }
      }));

      return assets;

    } catch (error) {
      clearTimeout(timeoutId);
      retries--;

      if (error.name === 'AbortError' && retries > 0) {
        await new Promise(resolve => setTimeout(resolve, baseDelay * (3 - retries)));
        continue;
      }

      if (retries === 0) {
        throw new Error(error.response?.data?.message || 'Failed to search assets on map');
      }

      await new Promise(resolve => setTimeout(resolve, baseDelay * (3 - retries)));
    }
  }

  throw new Error('Failed to search assets on map after retries');
};

export const getSearchMapResults = async (params = {}) => {
  try {


    const response = await api.get('api/assets/assets/map-search-results/', {
      params,
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'max-age=300'
      }
    });

 

    // Ensure we have valid response data
    if (!response.data) {
      throw new Error('No data received from server');
    }

    // Extract results from the correct path in the response
    let results = [];
    if (response.data?.results?.results && 
        Array.isArray(response.data.results.results)) {
      results = response.data.results.results;
    }

 

    // Transform the assets to ensure consistent format
    const transformedAssets = results.map(asset => ({
      id: asset.id || '',
      name: asset.name || '',
      slug: asset.slug || '',
      hourly_rate: parseFloat(asset.hourly_rate) || 0,
      description: asset.description || '',
      randomized_location: {
        latitude: parseFloat(asset.location?.latitude || asset.latitude || 0),
        longitude: parseFloat(asset.location?.longitude || asset.longitude || 0),
        is_exact: Boolean(asset.location?.is_exact)
      },
      category: {
        id: asset.category?.id || '',
        name: asset.category?.name || '',
        slug: asset.category?.slug || ''
      },
      is_available: Boolean(asset.is_available),
      images: Array.isArray(asset.images) ? asset.images : [],
      image_url: asset.image_url || (Array.isArray(asset.images) ? asset.images[0] : ''),
      average_rating: parseFloat(asset.average_rating) || 0,
      total_rentals: parseInt(asset.total_rentals) || 0,
      review_count: parseInt(asset.review_count) || 0,
      location: {
        name: asset.location?.name || '',
        latitude: parseFloat(asset.location?.latitude || 0),
        longitude: parseFloat(asset.location?.longitude || 0),
        ...asset.location
      }
    }));


    // Get the bounds from the nested structure
    const bounds = response.data.results?.bounds || null;

    return {
      data: {
        assets: transformedAssets,
        total_assets: response.data.count || 0,
        bounds: bounds
      }
    };
  } catch (error) {

    throw error;
  }
};
const validateLocationBounds = (bounds) => {
  const { north, south, east, west } = bounds;

  if (!north || !south || !east || !west) {
    throw new Error('Missing required bounds parameters');
  }

  if (north < south) {
    throw new Error('North latitude must be greater than south latitude');
  }

  if (Math.abs(north) > 90 || Math.abs(south) > 90) {
    throw new Error('Latitude must be between -90 and 90 degrees');
  }

  if (Math.abs(east) > 180 || Math.abs(west) > 180) {
    throw new Error('Longitude must be between -180 and 180 degrees');
  }

  return true;
};


export const getMapSearchResultsDetails = async (clusterId, params = {}) => {
  return retryWithBackoff(async () => {
    try {
      const response = await api.get(`api/assets/assets/map-search-results/${clusterId}/`, {
        params,
        headers: {
          'Cache-Control': 'max-age=300'
        }
      });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  });
};

export const searchAssets = async (params = {}) => {
  return retryWithBackoff(async () => {
    const queryParams = new URLSearchParams();

    const possibleParams = [
      'search', 'page', 'ordering', 'category', 'location', 'min_price', 'max_price',
      'pickup_date', 'return_date', 'condition', 'available', 'featured', 'min_rating',
      'vendor'
    ];

    possibleParams.forEach(param => {
      if (params[param] !== undefined && params[param] !== null) {
        queryParams.append(param, params[param]);
      }
    });

    try {
      const response = await api.get(`api/assets/assets/?${queryParams.toString()}`, {
        headers: {
          'Cache-Control': 'max-age=300'
        }
      });
      return response;
    } catch (error) {
      handleApiError(error);
    }
  });
};

export const exploreAssetsByCategory = async (params = {}) => {
  try {
    // Validate required category parameter
    if (!params.category) {

      return {
        data: {
          results: [],
          count: 0,
          message: "Category parameter is required"
        }
      };
    }

    // Clean and validate parameters
    const cleanParams = {
      category: params.category,
      page: params.page || 1,
      limit: params.limit || 8,
      featured: params.featured === undefined ? true : params.featured,
      // Only include these parameters if they are valid values
      ...(params.search && params.search !== 'undefined' && { search: params.search }),
      ...(params.location && params.location !== 'undefined' && { location: params.location }),
      ...(params.min_price && params.min_price !== 'undefined' && { min_price: params.min_price }),
      ...(params.max_price && params.max_price !== 'undefined' && { max_price: params.max_price }),
      ...(params.pickup_date && params.pickup_date !== 'undefined' && { pickup_date: params.pickup_date }),
      ...(params.return_date && params.return_date !== 'undefined' && { return_date: params.return_date })
    };

    // Make the API request with cleaned parameters
    const response = await api.get('api/assets/assets/explore-by-category/', {
      params: cleanParams,
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Transform response data
    return {
      data: {
        results: response.data.results || [],
        count: response.data.count || 0,
        next: response.data.next || null,
        previous: response.data.previous || null,
        message: response.data.message || null
      }
    };

  } catch (error) {


    if (error.response) {
      // Log detailed error information in development
      if (process.env.NODE_ENV === 'development') {

      }

      // Handle specific error cases
      switch (error.response.status) {
        case 400:
          throw new Error(error.response.data?.message || 'Invalid category or parameters');
        case 404:
          throw new Error('Category not found');
        default:
          throw new Error(error.response.data?.error || 'Failed to fetch assets by category');
      }
    }

    // Handle network or other errors
    throw new Error('Network error occurred while fetching assets');
  }
};


export const getAssets = async (params = {}) => {
  try {
    const queryParams = new URLSearchParams();

    const validParams = {
      search: params.search,
      page: params.page,
      ordering: params.ordering,
      category: params.category,
      location: params.location,
      min_price: params.min_price,
      max_price: params.max_price,
      pickup_date: params.pickup_date,
      return_date: params.return_date,
      condition: params.condition,
      available: params.available,
      featured: params.featured,
      min_rating: params.min_rating,
      vendor: params.vendor,
      limit: params.limit
    };

    // Only append parameters that have valid values
    Object.entries(validParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== 'undefined') {
        queryParams.append(key, value);
      }
    });

    const response = await api.get(`api/assets/assets/?${queryParams.toString()}`, {
      timeout: 30000,
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'max-age=300'
      }
    });

    return response;
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Failed to fetch assets');
  }
};

// Modified getAssetAvailability function
export const getAssetAvailability = (params, queryParams, cancelToken) => {
  // Ensure start_date and end_date are present in queryParams
  if (!queryParams.start_date || !queryParams.end_date) {
    return Promise.reject(new Error("Both start_date and end_date are required."));
  }

  return api.get(`api/assets/assets/${params.slug}/availability/`, {
    params: queryParams,
    cancelToken: cancelToken?.token,
  }).then(response => {
    // Ensure the response data is in the expected format
    if (Array.isArray(response.data)) {
      return response.data;
    } else {

      throw new Error('Unexpected availability data format');
    }
  }).catch(error => {
    if (axios.isCancel(error)) {

    } else if (error.response) {

      throw new Error(error.response.data.error || 'Failed to fetch availability');
    } else if (error.request) {

      throw new Error('Network error occurred while fetching availability');
    } else {

      throw error;
    }
  });
};


// Asset management
export const createAsset = async (data) => {
  try {
    // Create FormData object if not already FormData
    const formData = data instanceof FormData ? data : new FormData();

    // If data is not FormData, append each field to FormData
    if (!(data instanceof FormData)) {
      Object.keys(data).forEach(key => {
        if (data[key] instanceof File) {
          // Handle single file
          formData.append(key, data[key], data[key].name);
        } else if (Array.isArray(data[key])) {
          // Handle array of files or other array data
          data[key].forEach((item, index) => {
            if (item instanceof File) {
              formData.append(`${key}`, item, item.name);
            } else {
              formData.append(`${key}`, item);
            }
          });
        } else if (typeof data[key] === 'object' && data[key] !== null) {
          // Handle nested objects
          formData.append(key, JSON.stringify(data[key]));
        } else if (data[key] !== null && data[key] !== undefined) {
          // Handle primitive values
          formData.append(key, data[key]);
        }
      });
    }

    // Log FormData contents in development
    if (process.env.NODE_ENV === 'development') {
      for (let pair of formData.entries()) {

      }
    }

    const config = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      },
      transformRequest: [(data, headers) => {
        delete headers['Content-Type']; // Let axios set the correct content type with boundary
        return data;
      }],
    };

    const response = await api.post('api/assets/assets/', formData, config);
    return response.data;
  } catch (error) {

    if (error.response) {


      // Throw error with more detailed message
      throw new Error(
        error.response.data.message ||
        error.response.data.detail ||
        error.response.data.error ||
        'Failed to create asset'
      );
    }
    throw error;
  }
};


export const updateAsset = createApiFunction('put', 'api/assets/assets/{slug}/');
export const deleteAsset = createApiFunction('delete', 'api/assets/assets/{slug}/');
export const uploadAssetImages = async (assetId, formData) => {
  try {
    const config = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      },
      transformRequest: [(data, headers) => {
        delete headers['Content-Type'];
        return data;
      }],
    };

    const response = await api.post(`api/assets/assets/${assetId}/upload-images/`, formData, config);
    return response.data;
  } catch (error) {

    throw error;
  }
};


// Rentals
const validateRentalData = (rental) => {
  return {
    id: rental?.id || '',
    user: typeof rental?.user === 'object' 
      ? {
          id: rental.user?.id || '',
          username: rental.user?.username || 'Unknown User',
          email: rental.user?.email || ''
        }
      : { id: '', username: rental?.user || 'Unknown User', email: '' },
    asset: typeof rental?.asset === 'object'
      ? {
          id: rental.asset?.id || '',
          name: rental.asset?.name || 'Unknown Asset',
          hourly_rate: parseFloat(rental.asset?.hourly_rate) || 0
        }
      : { id: '', name: rental?.asset || 'Unknown Asset', hourly_rate: 0 },
    start_time: rental?.start_time || new Date().toISOString(),
    end_time: rental?.end_time || new Date().toISOString(),
    status: rental?.status || 'pending',
    total_amount: parseFloat(rental?.total_amount) || 0,
    payment_status: rental?.payment_status || 'pending',
    created_at: rental?.created_at || new Date().toISOString(),
    updated_at: rental?.updated_at || new Date().toISOString(),
    hours: parseInt(rental?.hours) || 0,
    quantity: parseInt(rental?.quantity) || 1,
    notes: rental?.notes || '',
    pickup_location: rental?.pickup_location || '',
    return_location: rental?.return_location || '',
    contract_signed: Boolean(rental?.contract_signed)
  };
};

// Enhanced rental functions with proper error handling
export const getRentalHistory = async (params = {}) => {
  try {
    const response = await api.get('api/rentals/history/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
        status: params.status || '',
        from_date: params.from_date,
        to_date: params.to_date,
        ordering: params.ordering || '-start_time',
        limit: params.limit || 10
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Handle both array and paginated responses
    let rentalsData;
    let totalCount;

    if (Array.isArray(response.data)) {
      rentalsData = response.data;
      totalCount = response.data.length;
    } else if (response.data.results) {
      rentalsData = response.data.results;
      totalCount = response.data.count;
    } else {
      throw new Error('Invalid response format');
    }

    // Normalize each rental object
    const normalizedRentals = rentalsData.map(validateRentalData);

    return {
      data: {
        results: normalizedRentals,
        count: totalCount,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };

  } catch (error) {

    if (error.response?.status === 401) {
      throw new Error('Authentication required. Please log in.');
    }
    if (error.response?.status === 403) {
      throw new Error('You do not have permission to view rental history.');
    }
    throw new Error(error.response?.data?.message || 'Failed to fetch rental history');
  }
};

export const getRentalDetails = async (id) => {
  try {
    const response = await api.get(`api/rentals/${id}/`);
    return validateRentalData(response.data);
  } catch (error) {

    if (error.response?.status === 404) {
      throw new Error('Rental not found');
    }
    throw new Error(error.response?.data?.message || 'Failed to fetch rental details');
  }
};

export const createRental = async (rentalData) => {
  try {
    // Validate required fields
    if (!rentalData.asset_id || !rentalData.start_time || !rentalData.end_time) {
      throw new Error('Missing required rental information');
    }

    const response = await api.post('api/rentals/', rentalData);
    return validateRentalData(response.data);
  } catch (error) {
  
    throw new Error(error.response?.data?.message || 'Failed to create rental');
  }
};

export const updateRentalStatus = async (id, statusData) => {
  try {
    if (!statusData.status) {
      throw new Error('Status is required');
    }

    const response = await api.patch(`api/rentals/${id}/`, {
      status: statusData.status,
      notes: statusData.notes
    });
    
    return validateRentalData(response.data);
  } catch (error) {
  
    if (error.response?.status === 404) {
      throw new Error('Rental not found');
    }
    throw new Error(error.response?.data?.message || 'Failed to update rental status');
  }
};

// Additional rental management functions
export const extendRental = async (id, endTime) => {
  try {
    const response = await api.post(`api/rentals/${id}/extend/`, {
      end_time: endTime
    });
    return validateRentalData(response.data);
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Failed to extend rental');
  }
};

export const cancelRental = async (id, reason) => {
  try {
    const response = await api.post(`api/rentals/${id}/cancel/`, {
      cancellation_reason: reason
    });
    return validateRentalData(response.data);
  } catch (error) {
 
    throw new Error(error.response?.data?.message || 'Failed to cancel rental');
  }
};

export const getRentalAnalytics = async (params = {}) => {
  try {
    const response = await api.get('api/rentals/analytics/', {
      params: {
        from_date: params.from_date,
        to_date: params.to_date,
        asset_id: params.asset_id,
        status: params.status
      }
    });
    return response.data;
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Failed to fetch rental analytics');
  }
};

export const getRentalContract = async (id) => {
  try {
    const response = await api.get(`api/rentals/${id}/contract/`);
    return response.data;
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Failed to fetch rental contract');
  }
};

export const signRentalContract = async (id, signature) => {
  try {
    const response = await api.post(`api/rentals/${id}/sign-contract/`, {
      signature
    });
    return validateRentalData(response.data);
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Failed to sign rental contract');
  }
};

export const generateRentalInvoice = async (id) => {
  try {
    const response = await api.get(`api/rentals/${id}/invoice/`);
    return response.data;
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to generate rental invoice');
  }
};


// Cart

export const cartApi = {
  getCart: async () => {
    try {
      const response = await api.get('api/cart/');
      
      // Normalize and validate the cart data structure
      const cartData = response.data;
      return {
        items: cartData.items || [],
        total_items: cartData.total_items || 0,
        total_price: cartData.total_price || 0,
        created_at: cartData.created_at || new Date().toISOString(),
        user: cartData.user || null
      };
    } catch (error) {
   
      throw error;
    }
  },

  addToCart: async (data) => {
    try {
      const requestData = {
        asset_id: parseInt(data.assetId || data.asset_id),
        quantity: parseInt(data.quantity || 1),
        hours: parseInt(data.hours || 1)
      };

      const response = await api.post('api/cart/add_item/', requestData);
      return response.data;
    } catch (error) {

      throw error;
    }
  },

  removeFromCart: async (assetId) => {
    try {
      const response = await api.post('api/cart/remove_item/', {
        asset_id: parseInt(assetId)
      });
      return response.data;
    } catch (error) {

      throw error;
    }
  },

  updateCartItem: async (data) => {
    try {
      const requestData = {
        asset_id: parseInt(data.assetId || data.asset_id)
      };

      if (data.quantity !== undefined) {
        requestData.quantity = parseInt(data.quantity);
      }
      if (data.hours !== undefined) {
        requestData.hours = parseInt(data.hours);
      }

      const response = await api.post('api/cart/update_item/', requestData);
      return response.data;
    } catch (error) {
   
      throw error;
    }
  },

  clearCart: async () => {
    try {
      const response = await api.post('api/cart/clear/');
      return response.data;
    } catch (error) {
 
      throw error;
    }
  }
};

// Export individual functions
export const getCart = () => cartApi.getCart();
export const addToCart = (data) => cartApi.addToCart(data);
export const removeFromCart = (assetId) => cartApi.removeFromCart(assetId);
export const updateCartItem = (data) => cartApi.updateCartItem(data);
export const clearCart = () => cartApi.clearCart();


export const applyCoupon = (code) => {
  return api.post('api/cart/apply_coupon/', { code })
    .then(response => response.data)
    .catch(handleApiError);
};


// Wishlist
// Simple wishlist data validator
const validateWishlistItem = (item) => ({
  id: item?.id || '',
  user: {
    username: item?.user?.username || 'Unknown User'
  },
  asset: {
    name: item?.asset?.name || 'Unknown Asset',
    hourly_rate: parseFloat(item?.asset?.hourly_rate) || 0
  },
  added_at: item?.added_at || new Date().toISOString()
});


export const getWishlist = createApiFunction('get', 'api/wishlist/');
export const addToWishlist = createApiFunction('post', 'api/wishlist/add_item/');
export const removeFromWishlist = createApiFunction('post', 'api/wishlist/remove_item/');
export const clearWishlist = createApiFunction('post', 'api/wishlist/clear/');


// Reviews
export const getMyReviews = createApiFunction('get', 'api/reviews/my-reviews/');
export const createReview = async (slug, reviewData) => {
  try {
    const response = await api.post(`api/reviews/assets/${slug}/reviews/`, reviewData);
    return response.data;
  } catch (error) {

    throw error;
  }
};

// Orders
export const confirmOrderPayment = createApiFunction('post', 'api/orders/{id}/confirm-payment/');


// Update the createOrder function
export const createOrder = async (orderData) => {
  try {

    const response = await api.post('api/orders/', orderData);

    return response.data;
  } catch (error) {

    if (error.response) {

    } else if (error.request) {

    } else {

    }
    throw error;
  }
};

// Add new functions for the multi-step process
export const requestVendorConfirmation = (orderId) => {
  return api.post(`api/orders/${orderId}/vendor-confirm/`)
    .then(response => response.data)
    .catch(handleApiError);
};

export const vendorRejectOrder = (orderId) => {
  return api.post(`api/orders/${orderId}/vendor-reject/`)
    .then(response => response.data)
    .catch(handleApiError);
};
export const initiateOrderPayment = createApiFunction('post', 'api/orders/{id}/initiate-payment/');
export const markOrderAsPaid = createApiFunction('post', 'api/orders/{id}/mark-as-paid/');
export const applyCouponToOrder = createApiFunction('post', 'api/orders/{id}/apply-coupon/');
export const updateOrderShippingInfo = createApiFunction('post', 'api/orders/{id}/update-shipping-info/');
export const updateOrderBillingInfo = createApiFunction('post', 'api/orders/{id}/update-billing-info/');



export const getVendorDashboard = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      throw new Error('No access token available');
    }

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 100000); // 100 second timeout

    try {
      const response = await api.get('api/users/vendors/dashboard/', {
        signal: controller.signal,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': 'application/json',
          'Cache-Control': 'no-cache',
          'X-Requested-With': 'XMLHttpRequest'
        },
        validateStatus: status => status === 200
      });

      clearTimeout(timeoutId);

      if (!response.data) {
        throw new Error('No data received from dashboard endpoint');
      }

      // Validate response data
      if (typeof response.data !== 'object') {
        throw new Error('Invalid response format from dashboard endpoint');
      }

      // Normalize the response data
      return validateDashboardData(response.data);
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Dashboard request timed out. Please try again.');
    }

    if (error.response) {
      switch (error.response.status) {
        case 401:
          throw new Error('Authentication expired. Please log in again.');
        case 403:
          throw new Error('You do not have permission to access the vendor dashboard.');
        case 404:
          throw new Error('Vendor dashboard endpoint not found.');
        case 500:
          throw new Error('Server error. Please try again later.');
        default:
          throw new Error(
            error.response.data?.message ||
            error.response.data?.detail ||
            'Failed to fetch dashboard data'
          );
      }
    }

    if (error.request) {
      throw new Error('Network error. Please check your connection.');
    }

    throw error;
  }
};

const validateDashboardData = (data) => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid dashboard data format');
  }

  // Validate required fields
  const requiredFields = [
    'total_earnings',
    'pending_earnings',
    'paid_earnings',
    'total_service_fees',
    'total_assets',
    'active_rentals_count'
  ];

  requiredFields.forEach(field => {
    if (typeof data[field] === 'undefined') {
      throw new Error(`Missing required field: ${field}`);
    }
  });

  // Validate arrays
  if (!Array.isArray(data.monthly_earnings)) {
    throw new Error('monthly_earnings must be an array');
  }

  if (!Array.isArray(data.recent_rentals)) {
    throw new Error('recent_rentals must be an array');
  }

  return {
    ...data,
    // Ensure numeric fields are numbers
    total_earnings: Number(data.total_earnings),
    pending_earnings: Number(data.pending_earnings),
    paid_earnings: Number(data.paid_earnings),
    total_service_fees: Number(data.total_service_fees),
    total_assets: Number(data.total_assets),
    active_rentals_count: Number(data.active_rentals_count),
    // Ensure arrays are initialized
    monthly_earnings: data.monthly_earnings || [],
    recent_rentals: data.recent_rentals || [],
    assets: data.assets || [],
    orders: data.orders || []
  };
};


export const getVendorOrderStatistics = async () => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout

    try {
      const response = await api.get('api/orders/vendor-statistics/', {
        signal: controller.signal,
        headers: {
          'Accept': 'application/json',
          'Cache-Control': 'no-cache',
          'X-Requested-With': 'XMLHttpRequest'
        },
        validateStatus: status => status === 200
      });



      clearTimeout(timeoutId);

      if (!response.data || !Array.isArray(response.data.orders)) {
        throw new Error('Invalid order statistics format');
      }

      return {
        orders: response.data.orders || [],
        total_orders: response.data.total_orders || 0,
        pending_orders: response.data.pending_orders || 0,
        completed_orders: response.data.completed_orders || 0
      };
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  } catch (error) {


    if (error.name === 'AbortError') {
      throw new Error('Order statistics request timed out. Please try again.');
    }

    if (error.response) {

      switch (error.response.status) {
        case 401:
          throw new Error('Authentication expired. Please log in again.');
        case 403:
          throw new Error('You do not have permission to access order statistics.');
        default:
          throw new Error(error.response.data?.message || 'Failed to fetch order statistics');
      }
    }

    if (error.request) {

      throw new Error('Network error. Please check your connection.');
    }

    throw error;
  }
};


// Update or add these functions
export const getCustomerOrderDetails = async ({ id }) => {
  try {

    const response = await api.get(`api/orders/${id}/details/`);

    return response.data;
  } catch (error) {

    if (error.response) {

      if (error.response.status === 403) {
        throw new Error("You don't have permission to view this order.");
      } else if (error.response.status === 404) {
        throw new Error("Order not found. It may have been deleted or never existed.");
      }
    } else if (error.request) {

      throw new Error("No response received from the server.");
    } else {

    }
    throw error;
  }
};

export const cancelOrder = createApiFunction('post', 'api/orders/{id}/cancel/');
export const getOrderAnalytics = createApiFunction('get', 'api/orders/analytics/');
export const getCustomerOrderHistory = createApiFunction('get', 'api/orders/customer-history/');
export const createBulkOrders = createApiFunction('post', 'api/orders/bulk-create/');
export const exportOrders = createApiFunction('get', 'api/orders/export/');


// Vendors
export const getVendorEarningsDetails = createApiFunction('get', 'api/users/vendors/earnings/');
export const getVendorEarningsBreakdown = createApiFunction('get', 'api/users/vendors/earnings-breakdown/');
export const markEarningsAsPaid = createApiFunction('post', 'api/users/vendors/{id}/mark-earnings-paid/');
export const markVendorEarningsAsPaid = createApiFunction('post', 'api/vendors/{id}/mark-earnings-paid/');


// Admin Vendor Earnings
export const getAllVendorEarnings = createApiFunction('get', 'api/users/admin/all-vendor-earnings/');
export const markAllEarningsPaid = createApiFunction('post', 'api/users/admin/mark-all-earnings-paid/');



// Promotions
export const getPromotions = createApiFunction('get', 'api/promotions/');

// Notifications
const validateAdminNotificationData = (notification) => {
  return {
    id: notification?.id || '',
    message: notification?.message || '',
    notification_type: notification?.notification_type || 'general',
    is_read: Boolean(notification?.is_read),
    created_at: notification?.created_at || new Date().toISOString(),
    // Include any admin-specific fields
    severity: notification?.severity || 'info',
    action_required: Boolean(notification?.action_required),
    system_generated: Boolean(notification?.system_generated),
    // Related data references
    related_object_type: notification?.related_object_type || null,
    related_object_id: notification?.related_object_id || null
  };
};

// Get all admin notifications
export const getAdminNotifications = async () => {
  try {
    const response = await api.get('api/notifications/', {
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    // Handle the response data
    if (!response?.data) {
      throw new Error('Invalid response from notifications endpoint');
    }

    // Handle both array and paginated responses
    let notificationsData;
    if (Array.isArray(response.data)) {
      notificationsData = response.data;
    } else if (response.data.results) {
      notificationsData = response.data.results;
    } else {
      notificationsData = [];
    }

    // Validate and normalize each notification
    const normalizedNotifications = notificationsData.map(validateAdminNotificationData);

    return {
      data: normalizedNotifications,
      status: 'success'
    };

  } catch (error) {


    // Handle specific error cases
    if (error.response?.status === 401) {
      throw new Error('Authentication required. Please log in as an admin.');
    }

    if (error.response?.status === 403) {
      throw new Error('You do not have permission to view admin notifications.');
    }

    if (!error.response && error.request) {
      throw new Error('Network error. Please check your connection.');
    }

    // Generic error handler
    throw new Error(
      error.response?.data?.message || 
      error.response?.data?.detail || 
      'Failed to fetch admin notifications'
    );
  }
};

// Optional: Get admin notification statistics
export const getAdminNotificationStats = async () => {
  try {
    const response = await api.get('api/notifications/stats/', {
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    return {
      total: response.data?.total || 0,
      unread: response.data?.unread || 0,
      critical: response.data?.critical || 0,
      lastUpdated: response.data?.last_updated || new Date().toISOString()
    };
  } catch (error) {
  
    throw new Error('Failed to fetch notification statistics');
  }
};

// Optional: Clear all admin notifications
export const clearAdminNotifications = async () => {
  try {
    const response = await api.post('api/notifications/clear/');
    return response.data;
  } catch (error) {

    throw new Error('Failed to clear notifications');
  }
};

// Optional: Mark all notifications as read
export const markAllAdminNotificationsAsRead = async () => {
  try {
    const response = await api.post('api/notifications/mark_all_as_read/');
    return response.data;
  } catch (error) {
  
    throw new Error('Failed to mark notifications as read');
  }
};



export const getUnreadNotifications = createApiFunction('get', 'api/notifications/unread/');
export const markNotificationAsRead = createApiFunction('post', 'api/notifications/{id}/mark_as_read/');
export const markAllNotificationsAsRead = createApiFunction('post', 'api/notifications/mark_all_as_read/');
export const getNotifications = createApiFunction('get', 'api/notifications/');

const handleNotifications = async (params = {}) => {
  try {
    const response = await getNotifications(params);
    // Handle both paginated and non-paginated responses
    if (response && typeof response === 'object') {
      if (Array.isArray(response)) {
        return response;
      } else if (response.results && Array.isArray(response.results)) {
        return response.results;
      } else if (response.data && Array.isArray(response.data)) {
        return response.data;
      }
    }
    return []; // Return empty array as fallback
  } catch (error) {

    throw error;
  }
};


export const notifications = {
  getNotifications: async () => {
    try {
      const response = await api.get('api/notifications/');
      return response.data.results || response.data || [];
    } catch (error) {

      throw error;
    }
  },
  markAsRead: async (notificationId) => {
    try {
      const response = await api.post(`api/notifications/${notificationId}/mark_as_read/`);
      return response.data;
    } catch (error) {

      throw error;
    }
  },
  markAllAsRead: async () => {
    try {
      const response = await api.post('api/notifications/mark_all_as_read/');
      return response.data;
    } catch (error) {

      throw error;
    }
  }
};

// Newsletter
export const getNewsletterSubscribers = async (params = {}) => {
  try {
    const response = await api.get('api/newsletter/subscribers/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from newsletter subscribers endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {

    throw error;
  }
};
export const updateNewsletterSubscriber = createApiFunction('patch', 'api/newsletter/subscribers/{id}/');
export const deleteNewsletterSubscriber = createApiFunction('delete', 'api/newsletter/subscribers/{id}/');
export const subscribeNewsletter = (email) => {
  return api.post('api/newsletter/subscribe/', { email })
    .then(response => response.data)
    .catch(handleApiError);
};


// Reports
export const generateReport = async (params) => {
  try {
    const response = await api.post('api/reports/generate/', params);
    return response.data;
  } catch (error) {

    throw error;
  }
};



// System
export const getSystemSettings = createApiFunction('get', 'api/system/settings/');

// Contact
export const submitContactForm = createApiFunction('post', 'api/contact/');
export const getContacts = async (params = {}) => {
  try {
    const response = await api.get('api/contact/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from contacts endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {
 
    throw error;
  }
};
export const createContact = createApiFunction('post', 'api/contact/');
export const updateContact = createApiFunction('put', 'api/contact/{id}/');
export const deleteContact = createApiFunction('delete', 'api/contact/{id}/');


// Payment-related functions
// Payment data validator helper
const validatePaymentData = (payment) => {
  return {
    id: payment?.id || '',
    amount: parseFloat(payment?.amount) || 0,
    currency: payment?.currency || 'KES',
    status: payment?.status || 'pending',
    payment_method: payment?.payment_method || 'N/A',
    transaction_id: payment?.transaction_id || 'N/A',
    created_at: payment?.created_at || new Date().toISOString(),
    user: payment?.user ? {
      id: payment.user.id || '',
      username: payment.user.username || 'Anonymous',
      email: payment.user.email || ''
    } : null,
    order: payment?.order ? {
      id: payment.order.id || '',
      order_number: payment.order.order_number || '',
      total_amount: parseFloat(payment.order.total_amount) || 0
    } : null,
    metadata: payment?.metadata || {}
  };
};

// Get all payments with pagination and filtering
export const getPayments = async (params = {}) => {
  try {
    const response = await api.get('api/payments/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
        ordering: params.ordering || '-created_at',
        status: params.status || '',
        payment_method: params.payment_method || '',
        limit: params.limit || 10
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    if (!response?.data) {
      throw new Error('Invalid response from server');
    }

    // Handle both array and paginated responses
    let paymentsData;
    let totalCount;

    if (Array.isArray(response.data)) {
      paymentsData = response.data;
      totalCount = response.data.length;
    } else if (response.data.results) {
      paymentsData = response.data.results;
      totalCount = response.data.count;
    } else {
      throw new Error('Invalid response format');
    }

    // Normalize each payment object
    const normalizedPayments = paymentsData.map(validatePaymentData);

    return {
      data: {
        results: normalizedPayments,
        count: totalCount,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };

  } catch (error) {
  
    throw new Error(error.response?.data?.message || 'Failed to fetch payments');
  }
};

// Get payment details by ID
export const getPaymentDetails = async (paymentId) => {
  try {
    const response = await api.get(`api/payments/${paymentId}/`);
    return validatePaymentData(response.data);
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to fetch payment details');
  }
};

// Update payment status
export const updatePaymentStatus = async (paymentId, statusData) => {
  try {
    const response = await api.patch(`api/payments/${paymentId}/status/`, {
      status: statusData.status,
      notes: statusData.notes
    });
    return validatePaymentData(response.data);
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to update payment status');
  }
};

// Get payment summary/statistics
export const getPaymentSummary = async (params = {}) => {
  try {
    const response = await api.get('api/payments/summary/', {
      params: {
        from_date: params.from_date,
        to_date: params.to_date,
        payment_method: params.payment_method
      }
    });
    
    return {
      total_amount: parseFloat(response.data.total_amount) || 0,
      total_count: parseInt(response.data.total_count) || 0,
      successful_amount: parseFloat(response.data.successful_amount) || 0,
      successful_count: parseInt(response.data.successful_count) || 0,
      failed_amount: parseFloat(response.data.failed_amount) || 0,
      failed_count: parseInt(response.data.failed_count) || 0,
      payment_methods_breakdown: response.data.payment_methods_breakdown || []
    };
  } catch (error) {
    
    throw new Error(error.response?.data?.message || 'Failed to fetch payment summary');
  }
};

export const cancelPaypalPayment = createApiFunction('get', 'api/payments/paypal/cancel/');
export const confirmPayment = createApiFunction('post', 'api/payments/confirm/');
export const initiatePayment = async (paymentData) => {
  try {
    const response = await api.post('api/payments/initiate/', paymentData);

    return response.data;
  } catch (error) {

    throw error;
  }
};

export const queryMpesaTransaction = async (queryData) => {
  try {
    const response = await api.post('api/payments/mpesa/query/', queryData);

    return response.data;
  } catch (error) {

    throw error;
  }
};

export const checkPaymentStatus = async (paymentId) => {
  try {
    const response = await api.get(`api/payments/status/`, { params: { payment_id: paymentId } });
    return response.data;
  } catch (error) {

    throw error;
  }
};

export const retryFailedPayment = createApiFunction('post', 'api/payments/retry/');

export const handlePaymentTimeout = async (paymentId) => {
  try {
    const response = await api.post('api/payments/timeout/', { payment_id: paymentId });
    return response.data;
  } catch (error) {

    throw error;
  }
};

// PayPal-specific functions

export const getPayPalConfig = async () => {
  try {
    const response = await api.get('api/payments/paypal/config/');
    return response.data;
  } catch (error) {

    throw error;
  }
};

export const initiatePaypalPayment = async (paymentData) => {
  try {
    const response = await api.post('api/payments/paypal/initiate/', paymentData);
    return response.data;
  } catch (error) {

    throw error;
  }
};

export const executePaypalPayment = async (paymentId, payerId) => {
  try {
    const response = await api.get('api/payments/paypal/execute/', {
      params: { paymentId, PayerID: payerId }
    });
    return response.data;
  } catch (error) {

    if (error.response) {

      if (error.response.status === 404) {
        throw new Error('Payment not found. Please check your payment details.');
      }
    } else if (error.request) {

      throw new Error('No response received from the server. Please try again.');
    } else {

      throw new Error('An error occurred while setting up the payment execution.');
    }
    throw error;
  }
};

export const getPaypalPaymentDetails = async (paymentId) => {
  try {
    const response = await api.get(`api/payments/paypal/details/${paymentId}/`);
    return response.data;
  } catch (error) {

    throw error;
  }
};


// Flutterwave-specific functions
export const initiateFlutterwavePayment = async (orderData) => {
  try {
    const response = await api.post('api/payments/flutterwave/initiate/', orderData);
    return response.data;
  } catch (error) {

    throw error;
  }
};

export const verifyFlutterwavePayment = async (transactionId) => {
  try {
    const response = await api.post('api/payments/flutterwave/verify/', { transaction_id: transactionId });
    return response.data;
  } catch (error) {

    throw error;
  }
};

// Reservation
export const reserveAsset = async (reservationData) => {
  try {
    const response = await api.post('api/rentals/reservations/', reservationData);
    return response.data;
  } catch (error) {

    throw error;
  }
};

export const createReservation = async (reservationData) => {
  try {
    // Format the data according to what the backend expects
    const formattedData = {
      asset_id: reservationData.asset_id,
      start_time: reservationData.start_time,
      end_time: reservationData.end_time,
      notes: `Rental period: ${reservationData.rental_period}, Hours: ${reservationData.hours}`,
    };

    const response = await api.post('api/rentals/reservations/', formattedData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    // Enhanced error handling
    if (error.response) {
      const errorMessage = error.response.data?.message || 
                          error.response.data?.error ||
                          error.response.data?.detail ||
                          'Failed to create reservation';
      throw new Error(errorMessage);
    } else if (error.request) {
      throw new Error('Network error. Please check your connection.');
    } else {
      throw new Error('Error creating reservation');
    }
  }
};

// Affiliate
export const getLoyaltyAccount = async () => {
  try {
    const response = await api.get('api/rewards/loyalty-accounts/');

    // Handle different response formats
    if (response.data.results && Array.isArray(response.data.results)) {
      // Paginated response
      return response.data.results;
    } else if (Array.isArray(response.data)) {
      // Array response
      return response.data;
    } else if (typeof response.data === 'object' && response.data !== null) {
      // Single object response
      return [response.data];
    } else {
      throw new Error('Invalid response format from loyalty account endpoint');
    }
  } catch (error) {
    throw error;
  }
};

export const addLoyaltyPoints = createApiFunction('post', 'api/rewards/loyalty-accounts/{id}/add_points/');
export const deductLoyaltyPoints = createApiFunction('post', 'api/rewards/loyalty-accounts/{id}/deduct_points/');
export const trackReferral = async (referralCode) => {
  try {
    const response = await api.post('/api/rewards/referrals/track_referral/', { referral_code: referralCode });
    return response.data;
  } catch (error) {

    throw error;
  }
};
// New function for campaign reports
export const generateCampaignReport = createApiFunction('post', 'api/rewards/campaign-reports/generate/');

// Loyalty Programs
export const getLoyaltyPrograms = async (params = {}) => {
  try {
    const response = await api.get('api/rewards/loyalty-programs/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from loyalty programs endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {

    throw error;
  }
};
export const createLoyaltyProgram = createApiFunction('post', 'api/rewards/loyalty-programs/');
export const updateLoyaltyProgram = createApiFunction('put', 'api/rewards/loyalty-programs/{id}/');
export const deleteLoyaltyProgram = createApiFunction('delete', 'api/rewards/loyalty-programs/{id}/');

// Coupons
export const getCoupons = async (params = {}) => {
  try {
    const response = await api.get('api/rewards/coupons/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from coupons endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {
  
    throw error;
  }
};
export const createCoupon = createApiFunction('post', 'api/rewards/coupons/');
export const updateCoupon = createApiFunction('put', 'api/rewards/coupons/{id}/');
export const deleteCoupon = createApiFunction('delete', 'api/rewards/coupons/{id}/');
export const useCoupon = createApiFunction('post', 'api/rewards/coupons/{id}/use/');
export const checkCouponValidity = (code) => {
  return api.get('api/rewards/coupons/check-validity/', {
    params: { code: code }
  }).catch(handleApiError);
};


// Promotional Campaigns
export const getPromotionalCampaigns = async (params = {}) => {
  try {
    const response = await api.get('api/rewards/promotional-campaigns/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from promotional campaigns endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {
    
    throw error;
  }
};
export const createPromotionalCampaign = createApiFunction('post', 'api/rewards/promotional-campaigns/');
export const updatePromotionalCampaign = createApiFunction('put', 'api/rewards/promotional-campaigns/{id}/');
export const deletePromotionalCampaign = createApiFunction('delete', 'api/rewards/promotional-campaigns/{id}/');
export const checkCampaignValidity = createApiFunction('get', 'api/rewards/promotional-campaigns/{id}/check-validity/');
export const incrementCampaignUses = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/increment-uses/');
export const addCampaignAssets = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/add-assets/');
export const removeCampaignAssets = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/remove-assets/');
export const activateCampaign = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/activate/');
export const deactivateCampaign = createApiFunction('post', 'api/rewards/promotional-campaigns/{id}/deactivate/');


export const getAffiliateData = async () => {
  try {
    const response = await api.get('api/rewards/affiliates/my_affiliate/');
    return response.data;
  } catch (error) {

    throw error;
  }
};
export const generateReferralLink = async () => {
  try {
    const response = await api.post('api/rewards/affiliates/generate_link/');
    return response.data;
  } catch (error) {

    throw error;
  }
};

// Rewards
export const getRewards = createApiFunction('get', 'api/rewards/rewards/');
export const redeemReward = createApiFunction('post', 'api/rewards/rewards/{id}/redeem/');


// Validate vendor
const validateVendorPaymentData = (payment) => {
  return {
    id: payment?.id || '',
    asset: {
      id: payment?.asset?.id || payment?.asset_id || '',
      name: payment?.asset?.name || payment?.asset_name || 'N/A'
    },
    order_number: payment?.order_number || 'N/A',
    gross_amount: parseFloat(payment?.gross_amount) || 0,
    net_amount: parseFloat(payment?.net_amount) || 0,
    payment_date: payment?.payment_date || null,
    is_paid_to_vendor: Boolean(payment?.is_paid_to_vendor),
    payout_transaction_id: payment?.payout_transaction_id || null,
    order: {
      id: payment?.order?.id || payment?.order_id || '',
      number: payment?.order?.number || payment?.order_number || 'N/A'
    }
  };
};

// Points Transactions
export const getPointsTransactions = async (params = {}) => {
  try {
    const response = await api.get('api/rewards/points-transactions/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from points transactions endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {
  
    throw error;
  }
};

// Referrals
export const getReferrals = async (params = {}) => {
  try {
    const response = await api.get('api/rewards/referrals/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate the response format
    if (!response?.data || !Array.isArray(response.data.results)) {
      throw new Error('Invalid response format from referrals endpoint');
    }

    return {
      data: {
        results: response.data.results,
        count: response.data.count || response.data.results.length,
        next: response.data.next || null,
        previous: response.data.previous || null
      }
    };
  } catch (error) {
   
    throw error;
  }
};

// Request Vendorship
export const requestVendorship = createApiFunction('post', 'api/users/request-vendorship/');


// System Configuration
export const getSystemConfigurations = createApiFunction('get', 'api/system/configurations/');
export const createSystemConfiguration = createApiFunction('post', 'api/system/configurations/');
export const updateSystemConfiguration = createApiFunction('put', 'api/system/configurations/{id}/');
export const deleteSystemConfiguration = createApiFunction('delete', 'api/system/configurations/{id}/');
export const getPublicConfigurations = createApiFunction('get', 'api/system/configurations/public/');
export const bulkUpdateConfigurations = createApiFunction('post', 'api/system/configurations/bulk_update/');

// Audit Logs
// Update the getAuditLogs function in api.js
export const getAuditLogs = async (params = {}) => {
  try {
    const response = await api.get('api/system/auditlogs/', {
      params: {
        page: params.page || 1,
        search: params.search || '',
        action_type: params.action_type || '',
        severity: params.severity || '',
        start_date: params.start_date,
        end_date: params.end_date,
        user: params.user,
        ordering: params.ordering || '-action_time',
        limit: params.limit || 100
      },
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    // Validate and normalize the response data
    if (!response?.data) {
      throw new Error('Invalid response from audit logs endpoint');
    }

    // Handle both array and paginated responses
    let logsData;
    let totalCount;

    if (Array.isArray(response.data)) {
      logsData = response.data;
      totalCount = response.data.length;
    } else if (response.data.results) {
      logsData = response.data.results;
      totalCount = response.data.count;
    } else {
      throw new Error('Unexpected response format');
    }

    // Normalize each log entry
    const normalizedLogs = logsData.map(log => ({
      id: log.id,
      user: log.user || null,
      user_username: log.user_username || 'Unknown User',
      action: log.action || 'unknown',
      content_type: log.content_type,
      object_id: log.object_id,
      object_repr: log.object_repr,
      action_time: log.action_time || log.timestamp || new Date().toISOString(),
      changes: log.changes || null,
      remote_addr: log.remote_addr,
      additional_data: log.additional_data || {},
      severity: log.severity || 'low',
      details: log.details || null
    }));

    return {
      data: {
        results: normalizedLogs,
        count: totalCount,
        next: response.data.next || null,
        previous: response.data.previous || null,
        page_size: params.limit || 100
      }
    };

  } catch (error) {
   
    
    // Handle specific error cases
    if (error.response) {
      switch (error.response.status) {
        case 401:
          throw new Error('Authentication required to access audit logs');
        case 403:
          throw new Error('You do not have permission to access audit logs');
        case 404:
          throw new Error('Audit logs endpoint not found');
        default:
          throw new Error(error.response.data?.message || 'Failed to fetch audit logs');
      }
    }

    // Handle network or other errors
    throw new Error('Network error occurred while fetching audit logs');
  }
};

// Add a new function to get audit log details if needed
export const getAuditLogDetails = async (logId) => {
  try {
    const response = await api.get(`api/system/auditlogs/${logId}/`);
    
    if (!response?.data) {
      throw new Error('Invalid response from audit log details endpoint');
    }

    return {
      data: {
        id: response.data.id,
        user: response.data.user,
        user_username: response.data.user_username || 'Unknown User',
        action: response.data.action || 'unknown',
        content_type: response.data.content_type,
        object_id: response.data.object_id,
        object_repr: response.data.object_repr,
        action_time: response.data.action_time || response.data.timestamp || new Date().toISOString(),
        changes: response.data.changes || null,
        remote_addr: response.data.remote_addr,
        additional_data: response.data.additional_data || {},
        severity: response.data.severity || 'low',
        details: response.data.details || null
      }
    };
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to fetch audit log details');
  }
};

// Add a function to flag audit logs if needed
export const flagAuditLog = async (logId, flagData) => {
  try {
    const response = await api.post(`api/system/auditlogs/${logId}/flag/`, flagData);
    return response.data;
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to flag audit log');
  }
};

// Add a function to get audit log summary statistics
export const getAuditLogSummary = async (params = {}) => {
  try {
    const response = await api.get('api/system/auditlogs/summary/', {
      params: {
        start_date: params.start_date,
        end_date: params.end_date,
        action_type: params.action_type
      }
    });

    if (!response?.data) {
      throw new Error('Invalid response from audit log summary endpoint');
    }

    return {
      data: {
        total_logs: response.data.total_logs || 0,
        logs_by_severity: response.data.logs_by_severity || {},
        logs_by_action: response.data.logs_by_action || {},
        logs_by_user: response.data.logs_by_user || {},
        recent_activity: response.data.recent_activity || []
      }
    };
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Failed to fetch audit log summary');
  }
};

export const exportAuditLogs = async (params = {}) => {
  try {
    const response = await api.get('api/system/auditlogs/export/', {
      params: {
        format: params.format || 'csv',
        start_date: params.start_date,
        end_date: params.end_date,
        action_type: params.action_type,
        severity: params.severity
      },
      responseType: 'blob'
    });

    return response.data;
  } catch (error) {
   
    throw new Error(error.response?.data?.message || 'Failed to export audit logs');
  }
};


export const flagAuditLogs = createApiFunction('post', 'api/system/auditlogs/flag/');


//  unused utility functions
export const VERSION_UTILS = {
  API_VERSION,
  createVersionedUrl,
  createTimeoutPromise
};

// Export and initialize the circuit breaker
export const circuitBreaker = new CircuitBreaker();

// Export and initialize the priority queue
export const priorityQueue = new PriorityQueue();

// Export storage configuration
export const STORAGE_CONFIG = {
  memory: storage,
  getStorage: () => storage
};

export {
  CircuitBreaker,
  PriorityQueue,
  CacheManager,
  RequestQueueManager,
  WebSocketManager,
  OfflineRequestQueue,
  generateRequestId,
  api,
  cache,
  storage
};


const apiService = {
  // User Authentication
  login, register, registerVendor, logout, getUserProfile, updateUserProfile,
  resetPassword, confirmResetPassword, activateAccount, resendActivation,
  loginWithGoogle, loginWithFacebook, refreshToken, checkAuthStatus,

  // User Profile
  getUserPreferences, updateUserPreferences, changePassword,
  deleteAccount, updateProfilePhoto, becomeVendor,

  // Assets
  getCategories, getLocations, getAssets, getAssetDetails, getAssetAvailability,
  reserveAsset, addMaintenanceRecord, updateAssetValue, toggleAssetFeatured,
  getAssetStats, getPopularAssets, exploreAssets, updateInventoryQuantity,
  getSimilarAssets, createAsset, updateAsset, deleteAsset, uploadAssetImages,
  getVendorAssets, getVendorEarnings, searchAssets, exploreAssetsByCategory,

  // Rentals
  createRental, getRentalDetails, updateRentalStatus, getRentalHistory,

  // Contact Management
  getContacts, createContact, updateContact, deleteContact,

  // Cart and Wishlist
  getWishlist, addToWishlist, removeFromWishlist, clearWishlist,
  getCart,
  addToCart,
  removeFromCart,
  updateCartItem,
  clearCart,

  // Reviews and Orders
  getAssetReviews, createReview, getMyReviews,

  // Misc
  getPromotions, generateReport, getSystemSettings,
  submitContactForm,

  // Admin-specific functions
  getAdminDashboard, createAdminUser, updateAdminUser,
  deleteAdminUser, getAdminUsers, getDashboardStats, getRecentOrders, updateOrderStatus,

  // Payment-related functions
  initiatePayment,
  cancelPaypalPayment,
  checkPaymentStatus,
  queryMpesaTransaction,
  confirmPayment,
  getPaymentSummary,
  retryFailedPayment,
  handlePaymentTimeout,
  getPayments,
  updatePaymentStatus,

  // PayPal-specific functions
  initiatePaypalPayment, executePaypalPayment, getPaypalPaymentDetails, getPayPalConfig,

  // Flutterwave-specific functions
  initiateFlutterwavePayment, verifyFlutterwavePayment,


  // Orders
  getUserOrders,
  getCustomerOrderDetails,
  getOrderDetails,
  createOrder,
  requestVendorConfirmation,
  vendorRejectOrder,
  initiateOrderPayment,
  markOrderAsPaid,
  applyCouponToOrder,
  updateOrderShippingInfo,
  updateOrderBillingInfo,
  getVendorOrderStatistics,
  cancelOrder,
  getOrderAnalytics,
  getCustomerOrderHistory,
  createBulkOrders,
  exportOrders,


  // Notifications
  getAdminNotifications,
  getNotifications: handleNotifications,
  getUnreadNotifications,



  // Vendors
  requestVendorship,
  getVendorEarningsDetails,
  getVendorEarningsBreakdown,
  markEarningsAsPaid,
  getVendorDashboard,
  confirmOrderPayment,
  markVendorEarningsAsPaid,

  // Admin Vendor Earnings
  getAllVendorEarnings,
  markAllEarningsPaid,

  // Vendor Payment Management
  getPendingVendorPayments: (params) => {
    return api.get('api/assets/asset-earnings/pending-vendor-payments/', { params })
      .then(response => response.data)
      .catch(handleApiError);
  },

  markAssetEarningPaidToVendor: (earningId, transactionId) => {
    return api.post(`api/assets/asset-earnings/${earningId}/mark_paid_to_vendor/`, { transaction_id: transactionId })
      .then(response => response.data)
      .catch(handleApiError);
  },

  // Newsletter
  subscribeNewsletter,
  getNewsletterSubscribers,
  updateNewsletterSubscriber,
  deleteNewsletterSubscriber,

  // Reservation
  createReservation,


  // Rewards and Loyalty
  getAffiliateData,
  generateReferralLink,
  getRewards,
  redeemReward,
  getPointsTransactions,
  getReferrals,
  trackReferral,
  getCoupons,
  getLoyaltyAccount,
  addLoyaltyPoints,
  deductLoyaltyPoints,
  getLoyaltyPrograms,
  createLoyaltyProgram,
  updateLoyaltyProgram,
  deleteLoyaltyProgram,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  checkCouponValidity,
  useCoupon,
  applyCoupon,

  // Promotional Campaigns
  getPromotionalCampaigns,
  createPromotionalCampaign,
  updatePromotionalCampaign,
  deletePromotionalCampaign,
  checkCampaignValidity,
  incrementCampaignUses,
  addCampaignAssets,
  removeCampaignAssets,
  activateCampaign,
  deactivateCampaign,

  // Campaign Reports
  generateCampaignReport,

  // System Configuration
  getSystemConfigurations,
  createSystemConfiguration,
  updateSystemConfiguration,
  deleteSystemConfiguration,
  getPublicConfigurations,
  bulkUpdateConfigurations,

  // Audit Logs
  getAuditLogs,
  getAuditLogSummary,
  flagAuditLogs,

  searchAssetsOnMap,
  getAssetLocation,
  getSearchMapResults,
  getMapSearchResultsDetails,
  validateLocationBounds,

  createCancelToken,

  batchRequests,
  cache,
  cleanup,



  wsManager,
  offlineQueue,
  api,
  CONFIG,
  handleApiError,
  retryWithBackoff,
  generateRequestId,

  // Add utility functions
  utils: {
    circuitBreaker,
    priorityQueue,
    createVersionedUrl,
    createTimeoutPromise,
    generateRequestId,
    normalizeUrl,
    replaceUrlParams,
  },

  //storage configuration
  storage: STORAGE_CONFIG,

  //version utilities
  version: VERSION_UTILS

};

export default apiService;